import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SortJsonData } from '../Pipes/SortingPipe';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { Meta, Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
/* eslint-disable */
export enum DisplayMode {
  Minute = 0,
  FiveMinute,
  TenMinute,
  FifteenMinute,
  ThirtyMinute,
  Hour,
  //TwoHour,
  //FourHour,
  Day,
  //TwoDay,
  Week,
  Month,
  Quarter,
  Year,
  FiveYear,
  TenYear
}
export enum DayOfWeek {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday
}

@Component({
  selector: 'Default',
  templateUrl: './Default.html'
})
export class DefaultTS {
  public AllowDebugger = true;
  public Message = '';
  public RedMessage = '';
  public BlueMessage = '';
  public IsUserLoggedIn = false;
  public IsAdminLoggedIn = false;
  public IsSemiAdminLoggedIn = false;
  public IsDriverLoggedIn = false;
  public IsHelperLoggedIn = false;
  public IsDriverAsContractorLoggedIn = false;
  public IsBlogAdminLoggedIn = false;
  public Role = [] as any;
  public UserRole = '';
  public UserRoleActual = '';
  public ClientInfo: any = {} as any;
  public UserName = '';
  public UserEmail = '';
  public UserMobile = '';
  public ProfilHandle = '';
  public ProfileImageURL = 'IMG';
  public UserInitials = 'IMG';
  public UserInitialsSignature = 'IMG';
  public IAcceptCookies = '';
  public CompaniesCount = 1;
  public SelectedCompanyID = 0;
  public SelectedCompanyName = '';
  public SelectedCompanyLogo = '';
  public IsEditor = false;
  public ManageCompanies = false;
  public ManageAdmin = false;
  public StaticPageData: any;
  public IsHideHeader: boolean;
  public IsHideFooter: boolean;
  public secretKey = "MANANDVAN@uk";

  public IsLocal = false;
  public BaseURL = 'https://app.finestvan.co.uk/';
  //public BaseURL = 'https://mnvapp.currentdomain.co.uk/';
  //public BaseURL = 'http://alpha.currentdomain.co.uk/';
  // public BaseURL = 'http://192.168.100.15:8095/';

  public IsStaging = false;
  public BaseWebURL = 'https://www.finestvan.co.uk/';

  public ShowLeftMenu = false;
  public PageURL: string;
  public OADashboardRedirectURL = '' as any;
  public OAEncryptedUserID = '' as any;
  public ShowHideMenuDropDownStatus = false;
  public http: HttpClient;
  public baseUrl: string;
  public UserTracking: any = {} as any;

  public l_oDtNow: Date;
  public l_oStartDate: Date;
  public l_oEndDate: Date;
  public OAAPIFundamentalCompaniesData = [] as any;
  public authURL = 'login';
  public isLeftMenuOpen = false;
  public FileUploaderStatus: number;
  public IsLogedInPage = false;
  public IsSafari = false;
  public URLComplete = '';
  public URLBase = '';
  public TotalUnreadMessages: any;
  public IsPorter = true;
  public IsNewQuote = false;
  public IsConversation = false;
  public PageStatusChecks = ['Status1', 'Status2'];
  public OnlyMainAdminPages = ["/earningsdashboard", "user", "/rates", "/moverrates", "movertypes", "vantypes", "van", "timeslot", "slots", "van_availability", "largevans", "systemconfiguration", "emailtemplate", "generaldata"];

  public FromAndToSelection = {} as any;

  public NumbersInWords = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen"];
  public VanAvailabilityTypes = [{ ID: 1, Name: "Once" }, { ID: 2, Name: "Every Day" }, { ID: 3, Name: "Once Every Week" }, { ID: 4, Name: "Once Every 2 Weeks" }, { ID: 5, Name: "Once Every 3 Weeks" }, { ID: 6, Name: "Once Every 4 Weeks" }, { ID: 7, Name: "Once Every Month" }];

  public PagesWithoutLogin = ["services", "man-with-a-van-london", "references", "insurance", "contact", "signup", "login", "home", "/", "quotes", "confirmation", "", "login", "forgotpassword", "resetpassword", "joinacc", "terms", "privacypolicy", "refundpolicy", "contactus", "joinus", "test", "terms-and-conditions", "privacy", "man-with-a-van-prices", "q", "moverstip", "unsubscribe", "cn"];

  public HideHeader = true;

  constructor(public datepipe: DatePipe, http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object, private router: Router, private toster: ToastrService, private meta: Meta, private titleService: Title,) {
    this.http = http;
    // this.baseUrl = baseUrl;
    this.ScrollToTop();
    this.IsHideHeader = false;
    this.IsHideFooter = false;
    this.PageURL = "";
    this.l_oDtNow = new Date();
    this.l_oStartDate = new Date();
    this.l_oEndDate = new Date();
    this.FileUploaderStatus = 0;
    this.ClientInfo.MNVClientID = 0;

    if (this.IsLocal)
      this.BaseURL = "https://localhost:44325/";

    if (this.IsStaging) {
      this.BaseWebURL = 'https://charlie.currentdomain.co.uk/';
    }

    //this.GetUnreadMessages();
  }

  public IsBaseURL() {
    var IsBasePage = false;
    if (isPlatformBrowser(this.platformId)) {

      this.URLComplete = window.location.href;
      var ThisURL = this.URLComplete.replace("https://", "");
      var res = ThisURL.split("/");
      this.URLBase = "https://" + res[0] + "/";
      if (this.URLComplete == this.URLBase) {
        IsBasePage = true;
      }
    }
    return IsBasePage;
  }

  public ShowMessage(type: number, message: string) {
    switch (type) {
      case 1:
        this.toster.success(message, "Success");
        break;
      case 2:
        this.toster.error(message, "Error");
        break;
      case 3:
        this.toster.warning(message, "Warning");
        break;
      default:
        this.toster.info(message, "Info");
    }
  }

  public roundToNearest(numToRound: any, numToRoundTo: any) {
    return Math.round(numToRound / numToRoundTo) * numToRoundTo;
  }

  public GetIndexOfClosestNumber(p_arrNumbers: any, p_intNumber: any) {
    var i = 0 as any;
    var minDiff = Number.MAX_SAFE_INTEGER;
    var l_intIndex = -1;
    for (i in p_arrNumbers) {
      var m = Math.abs(p_intNumber - p_arrNumbers[i]);
      if (m < minDiff) {
        minDiff = m;
        l_intIndex = parseInt(i);
      }
    }
    return l_intIndex;
  }

  public ConvertServerDateToLocalDate(p_oDate: any) {
    var l_oDate = new Date(p_oDate); // val is in UTC
    var localOffset = l_oDate.getTimezoneOffset() * 60000;
    var localTime = l_oDate.getTime() - localOffset;

    l_oDate.setTime(localTime);
    return l_oDate.toLocaleString();
    //return new Date(Date.UTC(p_oDate.getFullYear(), p_oDate.getMonth(), p_oDate.getDate(), p_oDate.getHours(), p_oDate.getMinutes(), p_oDate.getSeconds())).toLocaleString();

    //var l_oNewDate = new Date(p_oDate.getTime() + p_oDate.getTimezoneOffset() * 60 * 1000);

    //var l_intOffset = p_oDate.getTimezoneOffset() / 60;
    //var l_intHours = p_oDate.getHours();
    //l_oNewDate.setHours(l_intHours - l_intOffset);

    //return l_oNewDate.toLocaleString();
  }
  public ConvertServerDateToLocalDateString(p_oDate: any) {
    var l_oDate = new Date(p_oDate); // val is in UTC
    var localOffset = l_oDate.getTimezoneOffset() * 60000;
    var localTime = l_oDate.getTime() - localOffset;

    l_oDate.setTime(localTime);
    return l_oDate.toLocaleDateString();
    //return new Date(Date.UTC(p_oDate.getFullYear(), p_oDate.getMonth(), p_oDate.getDate(), p_oDate.getHours(), p_oDate.getMinutes(), p_oDate.getSeconds())).toLocaleDateString();
  }
  public ConvertServerTimeZoneToLocalTimeZone(p_oDate: any) {
    var l_oDate = new Date(p_oDate); // val is in UTC
    var localOffset = l_oDate.getTimezoneOffset() * 60000;
    var localTime = l_oDate.getTime() - localOffset;

    l_oDate.setTime(localTime);
    return l_oDate;
  }

  public ConvertServerTimeZoneToLocalTimeZoneUsingJson(p_oGridData: any, p_strColumnName: any) {
    var l_oArrayOfUniqueValues = [];
    var l_intCount = 0;
    for (var i = 0; i < p_oGridData.length; i++) {
      l_oArrayOfUniqueValues[l_intCount] = Object.assign({}, p_oGridData[l_intCount]);

      var l_strValue = l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')];
      if (l_strValue != ' ' && l_strValue != '' && l_strValue != null) {
        var l_oDate = new Date(l_strValue); // val is in UTC
        if (new Date(l_oDate)) {
          var localOffset = l_oDate.getTimezoneOffset() * 60000;
          var localTime = l_oDate.getTime() - localOffset;

          l_oDate.setTime(localTime);
          l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')] = l_oDate;
        }
      }
      l_intCount++;
    }
    return l_oArrayOfUniqueValues;
  }

  public sortInputFirst(input: any, data: any, p_strColumnName: any) {
    var first = [];
    var others = [];

    for (var i = 0; i < data.length; i++) {
      if (data[i][eval('p_strColumnName')].toLowerCase() == input.toLowerCase()) {
        first.push(data[i]);
        data.splice(i, 1);
        i--;
      }
      else if (data[i][eval('p_strColumnName')].toLowerCase().indexOf(input.toLowerCase()) == 0) {
        first.push(data[i]);
        data.splice(i, 1);
        i--;
      }
    }

    for (var i = 0; i < data.length; i++) {
      var l_strValues = data[i]['CompanyName'].toLowerCase().split(input.toLowerCase());
      if (l_strValues.length > 0 && l_strValues[0] == "") {
        first.push(data[i]);
      }
      else {
        others.push(data[i]);
      }
    }

    //first = new SortJsonData().transform(first, eval('p_strColumnName'));
    //others = new SortJsonData().transform(others, eval('p_strColumnName'));
    return (first.concat(others));
  }

  public ConvertUSATimeZoneToLocalTimeZoneUsingJson(p_oGridData: any, p_strColumnName: any) {
    var l_oArrayOfUniqueValues = [];
    var l_intCount = 0;
    for (var i = 0; i < p_oGridData.length; i++) {
      l_oArrayOfUniqueValues[l_intCount] = Object.assign({}, p_oGridData[l_intCount]);

      var l_strValue = l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')];
      if (l_strValue != ' ' && l_strValue != '' && l_strValue != null) {
        var l_oDate = new Date(l_strValue); // val is in UTC
        if (new Date(l_oDate)) {
          var localOffset = l_oDate.getTimezoneOffset() * 60000;
          var localTime = l_oDate.getTime() - localOffset;

          l_oDate.setTime(localTime);
          l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')] = l_oDate;
        }
      }
      l_intCount++;
    }
    return l_oArrayOfUniqueValues;
  }

  public getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  //MM:: Get Unique Records Against The Column from OverAll Data
  public GetUniqueJsonRecordsAgainstNthColumn(p_oGridData: any, p_strColumnName: any) {
    var lookup = {} as any;
    var l_oArrayOfUniqueValues = [];
    for (var item, i = 0; item = p_oGridData[i++];) {
      var name = item[eval('p_strColumnName')];

      if (!(name in lookup)) {
        lookup[name] = 1;
        l_oArrayOfUniqueValues.push(item);
      }
    }
    return l_oArrayOfUniqueValues;
  }

  //MM:: Get Unique Records Against The Column from OverAll Data
  public GetUniqueRecordsAgainstNthColumn(p_oGridData: any, p_strColumnName: any) {
    var lookup = {} as any;
    var l_oArrayOfUniqueValues = [];
    for (var item, i = 0; item = p_oGridData[i++];) {
      var name = item[eval('p_strColumnName')];
      if (name != '' || name == 0) {
        if (!(name in lookup)) {
          lookup[name] = 1;
          l_oArrayOfUniqueValues.push(name);
        }
      }
    }
    return l_oArrayOfUniqueValues.sort();
  }

  //MM:: Get Unique Records Against The Column from OverAll Data
  public GetUniqueRecordsAgainstNthColumnWithoutSort(p_oGridData: any, p_strColumnName: any) {
    var lookup = {} as any;
    var l_oArrayOfUniqueValues = [];
    for (var item, i = 0; item = p_oGridData[i++];) {
      var name = item[eval('p_strColumnName')];

      if (!(name in lookup)) {
        lookup[name] = 1;
        l_oArrayOfUniqueValues.push(name);
      }
    }
    return l_oArrayOfUniqueValues;
  }

  //MM:: Get Unique Records Against The Column from OverAll Data
  public GetUniqueValuesAgainstNthColumn(p_oGridData: any, p_strColumnName: any) {
    var lookup = {} as any;
    var l_oArrayOfUniqueValues = [];
    for (var item, i = 0; item = p_oGridData[i++];) {
      var name = item[eval('p_strColumnName')];

      if (!(name in lookup)) {
        lookup[name] = 1;
        l_oArrayOfUniqueValues.push(item);
      }
    }
    return l_oArrayOfUniqueValues;
  }

  public FilterJsonExactlyMatching(p_oGridData: any, p_oColumn: any, p_strValue: any) {
    var l_arrFilteredData = [];
    var count = 0;
    if (p_oGridData) {
      for (var i = 0; i < p_oGridData.length; i++) {
        if (p_oGridData[i][p_oColumn] != undefined && p_strValue != undefined && p_oGridData[i][p_oColumn].toString().toLowerCase() == p_strValue.toString().toLowerCase()) {
          l_arrFilteredData[count] = p_oGridData[i];
          count++;
        }
      }
    }
    return l_arrFilteredData;
  }

  public RemoveFromJsonExactlyMatching(p_oGridData: any, p_oColumn: any, p_strValue: any) {
    var l_arrFilteredData = [];
    var count = 0;
    if (p_oGridData) {
      for (var i = 0; i < p_oGridData.length; i++) {
        if (p_oGridData[i][p_oColumn] != p_strValue) {
          l_arrFilteredData[count] = p_oGridData[i];
          count++;
        }
      }
    }
    return l_arrFilteredData;
  }

  public FilterJsonContainingMatchingValues(p_oGridData: any, p_oColumn: any, p_strValue: any) {
    var l_arrFilteredData = [];
    var count = 0;
    if (p_oGridData) {
      for (var i = 0; i < p_oGridData.length; i++) {
        if (((p_oGridData[i][p_oColumn])).toString().indexOf(p_strValue.toString()) > -1) {
          l_arrFilteredData[count] = p_oGridData[i];
          count++;
        }
      }
    }
    return l_arrFilteredData;
  }

  public FilterJsonExactlyMatchingDates(p_oGridData: any, p_oColumn: any, p_strValue: any) {
    var l_arrFilteredData = [];
    var count = 0;
    for (var i = 0; i < p_oGridData.length; i++) {
      if (p_strValue != '' && p_strValue != null && p_strValue != ' ') {
        if (p_oGridData[i][p_oColumn] != ' ') {
          var l_oEnteredDate = new Date(p_strValue);
          var l_oDateInDatabase = new Date(p_oGridData[i][p_oColumn]);
          l_oEnteredDate.setHours(0, 0, 0, 0);
          l_oDateInDatabase.setHours(0, 0, 0, 0);
          if (l_oEnteredDate.getTime() == l_oDateInDatabase.getTime()) {
            l_arrFilteredData[count] = p_oGridData[i];
            count++;
          }
        }
      }
    }
    return l_arrFilteredData;
  }

  public FilterJsonBetweenDates(p_oGridData: any, p_oColumn: any, p_strStartValue: any, p_strEndValue: any) {
    var l_arrFilteredData = [];
    var count = 0;
    for (var i = 0; i < p_oGridData.length; i++) {
      if (p_oGridData[i][p_oColumn] != ' ') {
        var l_oStartDate = new Date(p_strStartValue);
        var l_oEndDate = new Date(p_strEndValue);
        var l_oDateInDatabase = new Date(p_oGridData[i][p_oColumn]);
        l_oStartDate.setHours(0, 0, 0, 0);
        l_oEndDate.setHours(0, 0, 0, 0);
        l_oDateInDatabase.setHours(0, 0, 0, 0);
        if (l_oStartDate.getTime() <= l_oDateInDatabase.getTime() && l_oDateInDatabase.getTime() <= l_oEndDate.getTime()) {
          l_arrFilteredData[count] = p_oGridData[i];
          count++;
        }
      }
    }
    return l_arrFilteredData;
  }

  public FormatDateInUKFormat(p_oDate: any) {
    return this.datepipe.transform(p_oDate, 'dd/MM/yyyy');
  }

  public FormatDateInUKFormatUsingJson(p_oGridData: any, p_strColumnName: any) {
    var l_oArrayOfUniqueValues = [];
    var l_intCount = 0;
    if (p_oGridData) {
      for (var i = 0; i < p_oGridData.length; i++) {
        l_oArrayOfUniqueValues[l_intCount] = Object.assign({}, p_oGridData[l_intCount]);
        var l_strValue = l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')];
        if (l_strValue.toString().indexOf('1900') > -1) {
          l_strValue = '';
          l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')] = l_strValue;
        }
        if (l_strValue != ' ' && l_strValue != '' && l_strValue != null) {
          var l_arrSplittedDate = l_strValue.toString().split('/');
          if (l_arrSplittedDate.length > 0) {
            var l_oDate = new Date(l_arrSplittedDate[1] + '/' + l_arrSplittedDate[0] + '/' + l_arrSplittedDate[2]); // val is in UTC
            var localOffset = l_oDate.getTimezoneOffset() * 60000;
            var localTime = l_oDate.getTime() - localOffset;

            l_oDate.setTime(localTime);
            l_oDate.setHours(0, 0, 0, 0);
            l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')] = l_oDate;
          }
          else
            l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')] = l_strValue;
        }
        l_intCount++;
      }
    }
    return l_oArrayOfUniqueValues;
  }
  //  AR  Show/ Hide a dive/////

  public PTSetVisibilty(p_strDivID: any, p_boolShow: any) {
    var l_strVisibility = "none";
    if (p_boolShow) {
      l_strVisibility = "block";
    }
    if (document.getElementById(p_strDivID) != null)
      document.getElementById(p_strDivID)!.style.display = l_strVisibility;
  }

  public PTSetVisibiltyTableRow(p_strDivID: any, p_boolShow: any) {
    var l_strVisibility = "none";
    if (p_boolShow) {
      l_strVisibility = "table-row";
    }
    if (document.getElementById(p_strDivID) != null)
      document.getElementById(p_strDivID)!.style.display = l_strVisibility;
  }

  public PTSetVisibilityAsHiddenOrVisible(p_strDivID: any, p_boolShow: any) {
    var l_strVisibility = "hidden";
    if (p_boolShow) {
      l_strVisibility = "visible";
    }
    document.getElementById(p_strDivID)!.style.visibility = l_strVisibility;
  }

  public getDateForComparison(p_strDateToConvert: any) {
    var l_arrSplittedDate = p_strDateToConvert.split('/');
    var l_dtToReturn = new Date(l_arrSplittedDate[2], parseInt(l_arrSplittedDate[1]) - 1, l_arrSplittedDate[0]);

    return l_dtToReturn;
  }

  //  AR  Show/ Hide a dive/////

  //MM:: ConvertSlashesSeperatedStringsToDates
  public ConvertSlashesSeperatedStringsToDates(p_oGridData: any, p_strColumnName: any) {
    var l_oArrayOfUniqueValues = [];
    var l_intCount = 0;
    for (var i = 0; i < p_oGridData.length; i++) {
      l_oArrayOfUniqueValues[l_intCount] = Object.assign({}, p_oGridData[l_intCount]);

      var l_strValue = l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')];
      if (l_strValue != ' ' && l_strValue != '' && l_strValue != null) {
        var l_arrSplittedData = l_strValue.split('/');

        if (l_arrSplittedData.length > 1) {
          var l_oDate = new Date(l_arrSplittedData[2], (l_arrSplittedData[1] - 1), l_arrSplittedData[0]);
          l_oArrayOfUniqueValues[l_intCount][eval('p_strColumnName')] = l_oDate;
        }
      }
      l_intCount++;
    }
    return l_oArrayOfUniqueValues;
  }

  public AssignGridDataValuesToOtherGridData(p_oGridData: any) {
    //var l_oArrayOfUniqueValues = [];
    //var l_intCount = 0;
    //if (p_oGridData != undefined) {
    //  for (var item, i = 0; item = p_oGridData[i++];) {
    //    l_oArrayOfUniqueValues[l_intCount] = p_oGridData[l_intCount];
    //    l_intCount++;
    //  }
    //}
    //return l_oArrayOfUniqueValues;

    return JSON.parse(JSON.stringify(p_oGridData));
  }

  public GetIndexExactlyMatchingColumn(p_oGridData: any, p_oColumn: any, p_strValue: any) {
    var l_arrFilteredData = [];
    var index = -1;
    if (p_oGridData.length != undefined) {
      for (var i = 0; i < p_oGridData.length; i++) {
        if (p_oGridData[i][p_oColumn] == p_strValue) {
          index = i;
          break;
        }
      }
    }
    return index;
  }

  public GetValueForExactlyMatchingColumn(p_oGridData: any, p_oColumn: any, p_strValue: any, p_strRequiredColumnValue: any) {
    var l_strValue = '';
    for (var i = 0; i < p_oGridData.length; i++) {
      if (p_oGridData[i][p_oColumn] == p_strValue) {
        l_strValue = p_oGridData[i][p_strRequiredColumnValue];
        break;
      }
    }
    return l_strValue;
  }

  public RemoveSelectedChildFromList(p_oGridData: any, p_oColumn: any, p_strValue: any) {
    var l_arrFilteredData = [];
    var index = -1;
    for (var i = 0; i < p_oGridData.length; i++) {
      if (p_oGridData[i][p_oColumn] == p_strValue) {
        index = i;
        break;
      }
    }
    if (index > -1) {
      p_oGridData.splice(index, 1);
    }
    return p_oGridData;
  }

  public SetMasterAnimation(l_boolShow: boolean) {
    if (document.getElementById('Animation')) {
      if (l_boolShow)
        document.getElementById('Animation')!.style.display = '';
      else
        document.getElementById('Animation')!.style.display = 'none';
    }
  }

  //#region ShowNotification

  public NotificationsArray = [] as any;

  public ShowNotification(p_Type: number, p_AutoHide: boolean, p_Message: string) {
    this.NotificationsArray.push({ Type: p_Type, AutoHide: p_AutoHide, Message: p_Message })
    setTimeout(() => {
      this.SetPositionAccordingToIndex();
    }, 100);
  }

  public SetPositionAccordingToIndex() {
    for (var i = 0; i < this.NotificationsArray.length; i++) {
      if (this.NotificationsArray[i].Type == 1) {
        this.ShowBlueNotification(this.NotificationsArray[i].Message, i);
        if (this.NotificationsArray[i].AutoHide)
          this.HideBlueNotification(i);
      }
      if (this.NotificationsArray[i].Type == 2) {
        this.ShowBlueNotification(this.NotificationsArray[i].Message, i);
        if (this.NotificationsArray[i].AutoHide)
          this.HideBlueNotification(i);
      }
      if (this.NotificationsArray[i].Type == 3) {
        this.ShowRedNotification(this.NotificationsArray[i].Message, i);
        if (this.NotificationsArray[i].AutoHide)
          this.HideRedNotification(i);
      }
    }
  }

  public ShowRedNotification(p_strMessage: any, p_index: any) {
    var l_oElement = document.getElementById('RedNotification' + p_index);
    if (l_oElement) {
      if (this.IAcceptCookies == 'no') {
        var AddPix = 80;
        for (var i = 0; i < p_index; i++) {
          AddPix += 55;
        }
        l_oElement.style.bottom = AddPix + 'px';
      } else {
        var AddPix2 = 0;
        for (var i = 0; i < p_index; i++) {
          AddPix2 += 55;
        }
        l_oElement.style.bottom = AddPix2 + 'px';
      }
      l_oElement.classList.add('showalert');
    }
    document.getElementById('RedMessage' + p_index)!.innerHTML = p_strMessage;
  }

  public ShowBlueNotification(p_strMessage: any, p_index: any) {
    var l_oElement = document.getElementById('BlueNotification' + p_index);
    if (l_oElement) {
      if (this.IAcceptCookies == 'no') {
        var AddPix = 80;
        for (var i = 0; i < p_index; i++) {
          AddPix += 55;
        }
        l_oElement.style.bottom = AddPix + 'px';
      } else {
        var AddPix2 = 0;
        for (var i = 0; i < p_index; i++) {
          AddPix2 += 55;
        }
        l_oElement.style.bottom = AddPix2 + 'px';
      }
      l_oElement.classList.add('showalert');
    }
    document.getElementById('BlueMessage' + p_index)!.innerHTML = p_strMessage;
  }

  public HideRedNotification(p_index: any) {
    setTimeout(() => {
      var l_oElement = document.getElementById('RedNotification' + p_index);
      if (l_oElement) {
        l_oElement.classList.remove('showalert');
        for (var i = p_index; i < this.NotificationsArray.length; i++) {
          var l_oElement1 = document.getElementById('RedNotification' + i);
          if (l_oElement1 == null) {
            l_oElement1 = document.getElementById('BlueNotification' + i);
          }
          if (l_oElement1) {
            var l_strBottom = l_oElement1.style.bottom;
            l_strBottom = l_strBottom.replace('px', '');
            l_oElement1.style.bottom = parseInt(l_strBottom) - 55 + 'px';
          }
        }
        this.NotificationsArray.splice(p_index, 1);
      }
    }, 10000);
  }

  public HideBlueNotification(p_index: any) {
    setTimeout(() => {
      var l_oElement = document.getElementById('BlueNotification' + p_index);
      if (l_oElement) {
        l_oElement.classList.remove('showalert');
        for (var i = p_index; i < this.NotificationsArray.length; i++) {
          var l_oElement1 = document.getElementById('BlueNotification' + i);
          if (l_oElement1 == null) {
            l_oElement1 = document.getElementById('RedNotification' + i);
          }
          if (l_oElement1) {
            var l_strBottom = l_oElement1.style.bottom;
            l_strBottom = l_strBottom.replace('px', '');
            l_oElement1.style.bottom = parseInt(l_strBottom) - 55 + 'px';
          }
        }
        this.NotificationsArray.splice(p_index, 1);
      }
    }, 10000);
  }

  public HideRedNotificationOnclick(p_index: any) {
    this.RedMessage = '';
    var l_oElement = document.getElementById('RedNotification' + p_index);
    if (l_oElement) {
      l_oElement.classList.remove('showalert');

      for (var i = p_index; i < this.NotificationsArray.length; i++) {
        var l_oElement1 = document.getElementById('RedNotification' + i);
        if (l_oElement1 == null) {
          l_oElement1 = document.getElementById('BlueNotification' + i);
        }
        if (l_oElement1) {
          var l_strBottom = l_oElement1.style.bottom;
          l_strBottom = l_strBottom.replace('px', '');
          l_oElement1.style.bottom = parseInt(l_strBottom) - 55 + 'px';
        }
      }
      this.NotificationsArray.splice(p_index, 1);
    }
  }

  //#endregion

  public ShowAlert(p_strMessage: any) {
    this.Message = p_strMessage;
    document.getElementById("ShowModal").click();
    document.getElementById("myModal").style.display = 'block';
    document.getElementById("Message").innerHTML = p_strMessage;
  }

  public ShowDriverAndHelperAlert(p_strMessage) {
    //alert(p_strMessage);
    this.ShowAlert(p_strMessage);
  }

  public HideAlert() {
    document.getElementById("myModal")!.style.display = 'none';
  }

  public GetColumnNamesFromJson(p_oGridData: any) {
    var l_arrColumns = [];
    var l_intCounter = 0;
    for (let obj of p_oGridData) {
      for (let key in obj) {
        if (key.toString().toLowerCase().indexOf('id') < 0) {
          l_arrColumns[l_intCounter] = key;
          l_intCounter++;
        }
      }
      break;
    }
    return l_arrColumns;
  }

  //////////// AR: Checkboxes/RadioButtons functions starts /////////

  public GetSelectedIDsFromList(p_strID: any) {
    var l_intSelectedIDs = "-1";
    let l_strList = <any>document.getElementsByName(p_strID);

    for (var i = 0, length = l_strList.length; i < length; i++) {
      if (l_strList[i].checked) {
        l_intSelectedIDs = l_intSelectedIDs + "," + l_strList[i].value;
      }
    }
    l_intSelectedIDs = l_intSelectedIDs.replace("-1,", "");
    return l_intSelectedIDs;
  }

  public SelectUnSelectSpecificElement(p_strID: any, p_intValue: any, p_boolSelect: any) {
    let l_strList = <any>document.getElementsByName(p_strID);

    for (var i = 0, length = l_strList.length; i < length; i++) {
      if (p_intValue == l_strList[i].value) {
        l_strList[i].checked = p_boolSelect;
      }
    }
  }

  public SelectUnSelectAllElement(p_strID: any, p_boolChecked: boolean) {
    var l_strList = <any>document.getElementsByName(p_strID);
    for (var i = 0, length = l_strList.length; i < length; i++) {
      l_strList[i].checked = p_boolChecked;
    }
  }

  public SelectUnselectAllButOne(p_strID: any, p_intValue: any, p_boolSelect: boolean) {
    var l_strList = <any>document.getElementsByName(p_strID);

    for (var i = 0, length = l_strList.length; i < length; i++) {
      if (p_intValue == l_strList[i].value) {
        l_strList[i].checked = p_boolSelect;
      }
      else {
        l_strList[i].checked = !p_boolSelect;
      }
    }
  }

  public CheckIfSelectedAnyElement(p_strID: any) {
    var l_boolIsSelectedAny = false;
    var l_strList = <any>document.getElementsByName(p_strID);

    for (var i = 0, length = l_strList.length; i < length; i++) {
      if (l_strList[i].checked) {
        l_boolIsSelectedAny = true;
      }
    }
    return l_boolIsSelectedAny;
  }

  public CheckIfSelectedAllElement(p_strID: any) {
    var l_boolIsSelectedAll = true;
    var l_strList = <any>document.getElementsByName(p_strID);

    for (var i = 0, length = l_strList.length; i < length; i++) {
      if (l_strList[i].checked) {
      }
      else {
        l_boolIsSelectedAll = false;
      }
    }
    return l_boolIsSelectedAll;
  }

  //  Gets the DaysPerMonth array, returns value of index corresponding to month number
  public GetDaysOfMonth(p_intMonth: number, p_intYear: number): number {
    // Check leap years if February
    if (p_intYear > -1) {
      if (p_intMonth == 1 && this.IsLeapYear(p_intYear)) {
        return 29;
      }
    }

    /* Return the number of days, corresponding to that month */
    return [31, 28, 31, 30, 31, 30,
      31, 31, 30, 31, 30, 31][p_intMonth];
  }

  //////////// AR: Checkboxes/RadioButtons functions ends /////////

  public GetStockPrice(p_oStockData: any, IsPatch: any) {
    var l_intPrice = -1;
    if (IsPatch) {
      if (p_oStockData != null && p_oStockData.length > 0) {
        for (var i = 0; i < p_oStockData.length; i++) {
          if (p_oStockData[i]["path"] != undefined && p_oStockData[i]["path"] != null && p_oStockData[i]["path"] == 'Last')
            l_intPrice = p_oStockData[i]["value"];
        }
      }
    }
    else {
      if (p_oStockData != null && p_oStockData.length > 0) {
        l_intPrice = p_oStockData[0]["Last"];
      }
    }
    return l_intPrice;
  }

  public GetMin_MaxValueFrom1DArray(p_arrDataValues: any) {
    var l_intMaxValue = Math.max.apply(Math, p_arrDataValues);
    var l_intMinValue = Math.min.apply(Math, p_arrDataValues);

    return [l_intMinValue, l_intMaxValue];
  }

  public GetMin_MaxValueFromJsonArray(p_arrJsonValues: any, p_strColumnName: any) {
    var l_arrSortedDate = new SortJsonData().transform(p_arrJsonValues, eval('p_strColumnName'));
    var l_intMinValue = 0;
    var l_intMaxValue = 0;
    if (l_arrSortedDate.length > 0) {
      l_intMinValue = l_arrSortedDate[0][eval('p_strColumnName')];
      l_intMaxValue = l_arrSortedDate[l_arrSortedDate.length - 1][eval('p_strColumnName')];
    }
    return [l_intMinValue, l_intMaxValue];
  }

  //  Gets the DaysPerMonth array, returns value of index corresponding to month number
  public getDatesOfMonth(p_intMonth: number, p_intYear: number): number {
    // Check leap years if February
    if (p_intYear > -1) {
      if (p_intMonth == 1 && this.IsLeapYear(p_intYear)) {
        return 29;
      }
    }

    /* Return the number of days, corresponding to that month */
    return [31, 28, 31, 30, 31, 30,
      31, 31, 30, 31, 30, 31][p_intMonth];
  }

  /* Returns true if leap year */
  public IsLeapYear(p_intYear: any): boolean {
    return ((p_intYear % 4 == 0) && (p_intYear % 100 != 0)) || (p_intYear % 400 == 0);
  }
  //MM:: Is Date in proper format
  public isValidDate(d: any) {
    d = new Date(d);
    return d instanceof Date && !isNaN(d.getTime());
  }

  public isValidEmailArray(p_strmail: any) {
    var EmialArray = p_strmail.split(',');
    var ReturnValue = false;
    for (var i = 0; i < EmialArray.length; i++) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(EmialArray[i])) {
        ReturnValue = true;
      }
      else {
        ReturnValue = false;
        break;
      }
    }
    return (ReturnValue);
  }

  public isValidEmail(p_strmail: any) {
    //"^[^@\s]+@[^@\s]+\.[^@\s]+$"
    if (/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(p_strmail)) {
      if (p_strmail.indexOf(",") > -1)
        return false;
      else if (p_strmail.indexOf(" ") > -1)
        return false;
      return (true)
    }
    return (false)
  }

  public IsValidCreditCard(p_strCreditCard: any) {
    if (/^\d{4}-?\d{4}-?\d{4}-?\d{4}/.test(p_strCreditCard)) {
      return (true)
    }
    return (false)
  }

  public IsValidZipCode(p_strZipCode: any) {
    if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(p_strZipCode)) {
      return (true)
    }
    return (false)
  }

  public getCardBrand(p_CreditCardNumber: any) {
    var jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$');
    // American Express
    var amex_regex = new RegExp('^3[47][0-9]{0,}$');
    // Diners Club
    var diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$');
    // Visa
    var visa_regex = new RegExp('^4[0-9]{0,}$'); //4
    // MasterCard
    var mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$');
    var maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$');
    //Discover
    var discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');

    p_CreditCardNumber = p_CreditCardNumber.replace(/\D/g, '');

    var CardType = 0;
    if (p_CreditCardNumber.match(jcb_regex)) {
      CardType = 7;
    } else if (p_CreditCardNumber.match(amex_regex)) {
      CardType = 4;
    } else if (p_CreditCardNumber.match(diners_regex)) {
      CardType = 8;
    } else if (p_CreditCardNumber.match(visa_regex)) {
      CardType = 1;
    } else if (p_CreditCardNumber.match(mastercard_regex)) {
      CardType = 2;
    } else if (p_CreditCardNumber.match(discover_regex)) {
      CardType = 5;
    } else if (p_CreditCardNumber.match(maestro_regex)) {
      if (p_CreditCardNumber[0] == '5') {
        CardType = 2;
      } else {
        CardType = 20;
      }
    }

    return CardType;
  }
  getParamValueQueryString(paramName: any) {
    if (isPlatformBrowser(this.platformId)) {
      const url = window.location.href;
      let paramValue: any;
      if (url.includes('?')) {
        const httpParams = new HttpParams({ fromString: url.split('?')[1] });
        paramValue = httpParams.get(paramName);
      }
      return paramValue;
    }
  }

  public getParamValueQueryStringV2(paramName: any) {

    if (isPlatformBrowser(this.platformId)) {
      const url = window.location.href;
      var paramValue = '';
      if (url.includes('?')) {
        const httpParams = new HttpParams({ fromString: url.split('?')[1] });
        paramValue = httpParams.get(paramName);
      }
      return paramValue;
    }

  }


  public showFreeTrialExpiredModal() {
    (<HTMLButtonElement>document.getElementById('btnOpenFreeTrialExpiredsModal')).click();
  }
  public showPausedAccountModal() {
    (<HTMLButtonElement>document.getElementById('btnOpenPausedAccountModal')).click();
  }
  //public MoverToPaymentPage() {
  // // this.router.navigate(['payment']);
  //}
  public SaveUserThemeForLoggedInUser(p_strTheme: any) {
    let data = {} as any;
    data.Theme = p_strTheme;
    this.SetMasterAnimation(true);
    this.http.post(this.baseUrl + 'api/VTUserSettings/SaveUserThemeForLoggedInUser', data).subscribe(result => {
      let l_arrResponse = result as any;
      this.SetMasterAnimation(false);
    }, error => console.error(error));
  }

  public UserTrackingSaveRecord(funcName?: string) {
    // let data = {} as any;

    // data.SiteSessionID = 0;
    // data.SiteUserID = 0;
    // data.Name = '';
    // data.URL = '';
    // data.StartTime = '01/01/1900';
    // data.EndTime = '01/01/1900';
    // data.CreatedByID = 0;
    // data.LastUpdatedByID = 0;
    // data.IsDeleted = false;
    // for (let field in data) {
    //   //if (this.UserTracking[field] !== undefined && this.UserTracking[field] != null)
    //   data[field] = this.UserTracking[field];
    // }
    // var URL = window.location.href;
    // data.URL = URL + ((funcName) ? '--' + funcName : '');

    // this.http.post('api/UserTracking/UserTrackingSave', data).subscribe(result => {
    // }, error => console.error(error));
  }

  public RemoveRecordsAfterSpecificIndex(p_arrData: any, p_intIndex: any) {
    p_arrData.splice(p_intIndex);
    return p_arrData;
  }

  //findChoices() {
  //  return this.OAAPIFundamentalCompaniesData.filter(item =>
  //    item.Name.toLowerCase().includes(this.VigSearchObject.SearchSymbol.toLowerCase())
  //  );
  //}

  public isInt(n: any) {
    return Number(n) === n && n % 1 === 0;
  }

  public isFloat(n: any) {
    return Number(n) === n && n % 1 !== 0;
  }

  //public PTCheckPasswordValidation(FieldValue, Message) {
  //  //  var l_strFieldValue = document.getElementById('txt' + FieldName).value;
  //  var RegExpresson = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  //  var l_boolValid = RegExpresson.test(FieldValue);
  //  if (l_boolValid) {
  //    Message = "";
  //  }
  //  else {
  //    if (Message.length > 0)
  //      Message = Message;
  //    else
  //      Message = "weak";
  //  }
  //  return { 'Valid': l_boolValid, 'Message': Message };
  //}

  public PTCheckPasswordValidation(FieldValue: any, Message: any) {
    //var RegExpresson = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    var RegExpresson = /^(?=.*["}{)(|,'?><_~!@#\$%\^&\*^%:;.`/-])/;
    //var l_boolValid = RegExpresson.test(FieldValue);
    var l_boolValid = true;
    var HasWhiteSpace = false;
    if (/\s/.test(FieldValue)) {
      // It has any kind of whitespace
      HasWhiteSpace = true;
    }
    if (HasWhiteSpace) {
      l_boolValid = false;
      IsAnyError = true;
      Message = "White spaces are not allowed.<br />";
    }
    var IsAnyError = false;
    if (FieldValue.length < 8) {
      Message += "A minimum of 8 characters required.<br />";
      IsAnyError = true;
      l_boolValid = false;
    }

    if (!(/[a-z]/.test(FieldValue))) {
      Message += "At least one lowercase letter is required.<br />";
      IsAnyError = true;
      l_boolValid = false;
    }

    if (FieldValue == FieldValue.toLowerCase()) {
      Message += "At least one capital letter is required.<br />";
      IsAnyError = true;
      l_boolValid = false;
    }

    if (!(/\d/.test(FieldValue))) {
      Message += "At least one number is required.<br />";
      IsAnyError = true;
      l_boolValid = false;
    }

    // if (/^[a-zA-Z0-9- ]*$/.test(FieldValue) == true) {
    if (!RegExpresson.test(FieldValue)) {
      Message += "At least one special character (e.g. @) is required.<br />";
      IsAnyError = true;
      l_boolValid = false;
    }
    // }

    return { 'Valid': l_boolValid, 'Message': Message };
  }

  public GetPreviousDayDate() {
    let DateAccordingToET = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
    this.l_oDtNow = new Date(DateAccordingToET);
    this.l_oStartDate = new Date(this.l_oDtNow.getFullYear(), this.l_oDtNow.getMonth(), this.l_oDtNow.getDate(), 9, 30, 0);
    this.l_oEndDate = new Date(this.l_oDtNow.getFullYear(), this.l_oDtNow.getMonth(), this.l_oDtNow.getDate(), 16, 0, 0);
    if (this.l_oDtNow.getDay() == 6) {
      this.l_oDtNow = new Date(this.l_oDtNow.getTime() - ((2 - 1) * 24 * 60 * 60 * 1000));
    }
    else if (this.l_oDtNow.getDay() == 0) {
      this.l_oDtNow = new Date(this.l_oDtNow.getTime() - ((3 - 1) * 24 * 60 * 60 * 1000));
    }
    else if (this.l_oDtNow.getDay() == 1 && this.l_oDtNow < this.l_oStartDate) {
      this.l_oDtNow = new Date(this.l_oDtNow.getTime() - ((4 - 1) * 24 * 60 * 60 * 1000));
    }
    else if (this.l_oDtNow.getDay() == 1 && this.l_oDtNow > this.l_oStartDate) {
      this.l_oDtNow = new Date(this.l_oDtNow.getTime() - ((3 - 1) * 24 * 60 * 60 * 1000));
    }
    else if (this.l_oDtNow.getDay() == 2 && this.l_oDtNow < this.l_oStartDate) {
      this.l_oDtNow = new Date(this.l_oDtNow.getTime() - ((4 - 1) * 24 * 60 * 60 * 1000));
    }
    else if (this.l_oDtNow < this.l_oStartDate) {
      this.l_oDtNow = new Date(this.l_oDtNow.getTime() - ((2 - 1) * 24 * 60 * 60 * 1000));
    }
    else {
      this.l_oDtNow = new Date(this.l_oDtNow.getTime() - ((1 - 1) * 24 * 60 * 60 * 1000));
    }
    return this.l_oDtNow;
  }

  public AddSufixToDate(value: any) {
    let suffix = 'th',
      day = value;

    if (day == 1 || day == 21 || day == 31) {
      suffix = 'st'
    } else if (day == 2 || day == 22) {
      suffix = 'nd';
    } else if (day == 3 || day == 23) {
      suffix = 'rd';
    }

    return suffix;
  }

  public IsMarketOpen() {
    let l_Open = false;
    let DateAccordingToET = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
    this.l_oDtNow = new Date(DateAccordingToET);
    var l_strTime = this.l_oDtNow.toLocaleTimeString('en-US');
    var l_intHour = this.l_oDtNow.getHours();
    var l_intMinute = this.l_oDtNow.getMinutes();
    if (this.l_oDtNow.getDay() == 0 || this.l_oDtNow.getDay() == 6) { // 0-6 sunday-saturday
      l_Open = false;
    }
    else if (l_intHour > 9 || (l_intHour == 9 && l_intMinute >= 30)) {
      l_Open = true;
    }
    return l_Open;
  }

  public GetMonthString(month: any): string {
    var monthString = "";

    switch (month + 1) {
      case 1: {
        monthString = "Jan";
        break;
      }
      case 2: {
        monthString = "Feb";
        break;
      }
      case 3: {
        monthString = "Mar";
        break;
      }
      case 4: {
        monthString = "Apr";
        break;
      }
      case 5: {
        monthString = "May";
        break;
      }
      case 6: {
        monthString = "Jun";
        break;
      }
      case 7: {
        monthString = "Jul";
        break;
      }
      case 8: {
        monthString = "Aug";
        break;
      }
      case 9: {
        monthString = "Sep";
        break;
      }
      case 10: {
        monthString = "Oct";
        break;
      }
      case 11: {
        monthString = "Nov";
        break;
      }
      case 12: {
        monthString = "Dec";
        break;
      }

      default: {
        monthString = "";
        break;
      }
    }
    return monthString;
  }

  public getOrdinalForNumber(n: any) {
    var s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return (s[(v - 20) % 10] || s[v] || s[0]);
  }

  public CalculatePercentage(p_dlValue: any, p_dlPerc: any, DecimalPoints: any) {
    var l_dlValue = 0;
    if (p_dlValue > 0) {
      l_dlValue = (p_dlValue * p_dlPerc) / 100;
    }
    //var a = parseFloat(l_dlValue.toFixed(DecimalPoints));
    return (this.RoundedToFixed(l_dlValue, 2));
  }
  public GenerateUniqueGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public RoundedToFixed(_float: any, _digits: any) {
    var rounded = Math.pow(10, _digits);
    return (Math.round(_float * rounded) / rounded).toFixed(_digits);
  }

  public ConvertDateToESTFormat(p_oDate: any) {
    var ESTFormat;
    let DateAccordingToET = new Date(p_oDate).toLocaleString("en-US", { timeZone: "America/New_York" });
    ESTFormat = new Date(DateAccordingToET);
    return p_oDate;
  }

  public CapitalizeFirstLetter(string: any) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  public TitleCase(string: any) {
    return string.split(" ").map((x: any) => (x == "U.S.") ? x : this.CapitalizeFirstLetter(x)).join(" ");
  }

  public LinearRegression(y: any, x: any) {
    var lr = {} as any;
    var n = y.length;
    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var sum_yy = 0;

    for (var i = 0; i < y.length; i++) {
      sum_x += x[i];
      sum_y += y[i];
      sum_xy += (x[i] * y[i]);
      sum_xx += (x[i] * x[i]);
      sum_yy += (y[i] * y[i]);
    }

    lr['slope'] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
    lr['intercept'] = (sum_y - lr['slope'] * sum_x) / n;
    lr['r2'] = Math.pow((n * sum_xy - sum_x * sum_y) / Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)), 2);

    return lr;
  }

  public GetDifferenceBetweenTwoDates(p_oFirstDate: any, p_oSecondDate: any) {
    var diff = Math.abs(new Date(p_oFirstDate).getTime() - new Date(p_oSecondDate).getTime());
    var diffDays = diff / (1000 * 3600 * 24);
    return diffDays;
  }

  public OAAPIFundamentalCompaniesDataGetCompleteGridData() {
    this.http.get(this.baseUrl + 'api/OAAPIFundamentalCompaniesData/OAAPIFundamentalCompaniesDataGetCompleteGridDataCustom').subscribe(result => {
      //var l_arrResponse = result as any;
      //this.OAAPIFundamentalCompaniesData = l_arrResponse[0];
    }, error => console.error(error));
  }

  ///////////////// Horizontal Date Axis //////////////////
  //ToDo: Update it properly and move its components into respective classes
  //#Region Horizontal Date Display Structure

  private fullNameMonthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "Septemebr", "October", "November", "December"] as any;
  private shortNameMonthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  private afterOrPastMidway = ["a", "p"];

  private isFullMonth: boolean = false;
  public startIndex = 0;
  private textFont = "normal 10px opensans";
  private strokeStyle = "#A09F9F";
  private fillStyle = "#A09F9F";

  public visibleDatesTimelinePositionArray = [] as any;

  public SetHorizontalAxisStyle(textFont: any, strokeStyle: any, fillStyle: any) {
    this.textFont = textFont;
    this.strokeStyle = strokeStyle;
    this.fillStyle = fillStyle;

    //  alert(this.textFont + "  " + this.strokeStyle + "  " + this.fillStyle);
  }

  public SwitchDisplayMode(startDisplayMode: DisplayMode, canvasContext: CanvasRenderingContext2D, dates: Date[], x0: number, y0: number, displayWidth: number, xScale: number) {
    for (var i = startDisplayMode; i <= DisplayMode.Year; i++) {
      if (this.IdentifyDisplayRange(i, dates, canvasContext, displayWidth)) {
        this.visibleDatesTimelinePositionArray = [];
        this.DrawHorizontalDates(i, canvasContext, dates, xScale, y0, x0);

        break;
      }
    }
  }

  private GetMonthQuarterString(month: any): string {
    var qurterString = "";

    switch (month) {
      case 0:
      case 1:
      case 2:
        qurterString = "JFM";
        break;
      case 3:
      case 4:
      case 5:
        qurterString = "AMJ";
        break;
      case 6:
      case 7:
      case 8:
        qurterString = "JAS";
        break;
      case 9:
      case 10:
      case 11:
        qurterString = "OND";
        break;
    }
    return qurterString;
  }

  private isDifferenceInMinutes(thisDate: any, lastDate: any, differenceInMinutes: any) {
    var isDifference = false;
    var currentDateTime = new Date(thisDate).getTime();
    var oldDateTime = new Date(lastDate).getTime();

    let diffInMilliSeconds = Math.abs(oldDateTime - currentDateTime) / 1000;
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;

    if (minutes % differenceInMinutes == 0) {
      isDifference = true;
    }

    return isDifference;
  }

  private IsTwoDayDifference(thisDate: Date, lastDate: Date): boolean {
    var is2DDiff = false;

    var currentDate = new Date(thisDate.getFullYear(), thisDate.getMonth(), thisDate.getDate()).getTime() / 86400000;
    var previousDate = new Date(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate()).getTime() / 86400000;
    var previousDateWeekDay = lastDate.getDay();

    if (previousDateWeekDay >= 1 && previousDateWeekDay <= 4 && currentDate - previousDate > 1) {
      is2DDiff = true;
    }
    else if (previousDateWeekDay == 5 && currentDate - previousDate > 3) { // Cover Weekends
      is2DDiff = true;
    }
    else {
      is2DDiff = false;
    }
    return is2DDiff;
  }

  private IsSameQuarter(currentDate: Date, previousDate: Date): boolean {
    var IsSameQuarter = false;
    var currentMonth: number = currentDate.getMonth();
    var previousMonth: number = previousDate.getMonth()

    switch (currentMonth) {
      case 0:
      case 1:
      case 2:
        if (previousMonth >= 0 && previousMonth <= 2) {
          IsSameQuarter = true;
        }
        else {
          IsSameQuarter = false;
        }
        break;
      case 3:
      case 4:
      case 5:
        if (previousMonth >= 3 && previousMonth <= 5) {
          IsSameQuarter = true;
        }
        else {
          IsSameQuarter = false;
        }
        break;
      case 6:
      case 7:
      case 8:
        if (previousMonth >= 6 && previousMonth <= 8) {
          IsSameQuarter = true;
        }
        else {
          IsSameQuarter = false;
        }
        break;
      case 9:
      case 10:
      case 11:
        if (previousMonth >= 9 && previousMonth <= 11) {
          IsSameQuarter = true;
        }
        else {
          IsSameQuarter = false;
        }
        break;
    }
    return IsSameQuarter;
  }

  private GetMonthAndYearFormat(month: number, year: number): string {
    var monthDayYear: string = ""

    if (!this.isFullMonth) {
      monthDayYear = this.shortNameMonthArray[month];
    }
    else {
      monthDayYear = this.fullNameMonthArray[month];
    }

    if (month == 0) {
      monthDayYear = monthDayYear + " " + year;
    }
    else {
      monthDayYear = monthDayYear;
    }

    return monthDayYear;
  }

  private GetMonthDayYearFormat(day: number, month: number, year: number): string {
    var monthDayYear: string = ""

    if (!this.isFullMonth) {
      monthDayYear = this.shortNameMonthArray[month];
    }
    else {
      monthDayYear = this.fullNameMonthArray[month];
    }

    if (month == 0 && day == 1) {
      monthDayYear = monthDayYear + " " + day + ", " + year;
    }
    else {
      monthDayYear = monthDayYear + " " + day;
    }

    return monthDayYear;
  }

  private GetHourWithAMPM(minute: number, hour: number, twelveHours: Boolean, minutesRequired: Boolean): string {
    var minutesString: string = "";
    var hoursString: string = "";

    if (minutesRequired) {
      if (minute < 10) {
        minutesString = ':0' + minute;
      }
      else {
        minutesString = ':' + minute;
      }
    }

    if (hour < 10) {
      hoursString = '0' + hour;
    }
    else {
      hoursString = '' + hour;
    }

    if (twelveHours) {
      if (hour == 12) {
        hoursString = hoursString + '' + minutesString + this.afterOrPastMidway[1];
      }
      else if (hour < 12) {
        if (hour == 0) {
          hoursString = '12' + minutesString + this.afterOrPastMidway[0];
        } else
          hoursString = hoursString + '' + minutesString + this.afterOrPastMidway[0];
      }
      else {
        hoursString = (hour - 12) + '' + minutesString + this.afterOrPastMidway[1];
      }
    }

    return hoursString
  }

  private GetDateString(date: number): string {
    if (date < 10) {
      return '0' + date;
    }
    else {
      return '' + date;
    }
  }

  private IdentifyDisplayRange(displayMode: DisplayMode, dates: Date[], canvasContext: CanvasRenderingContext2D, displayWidth: number): boolean {
    var isAllowed: boolean = false;

    switch (displayMode) {
      case DisplayMode.Minute:
        isAllowed = this.IsValidHoursMode(canvasContext, dates, displayWidth, true, 1);
        break;
      case DisplayMode.FiveMinute:
        isAllowed = this.IsValidHoursMode(canvasContext, dates, displayWidth, true, 5);
        break;
      case DisplayMode.TenMinute:
        isAllowed = this.IsValidHoursMode(canvasContext, dates, displayWidth, true, 10);
        break;
      case DisplayMode.FifteenMinute:
        isAllowed = this.IsValidHoursMode(canvasContext, dates, displayWidth, true, 15);
        break;
      case DisplayMode.ThirtyMinute:
        isAllowed = this.IsValidHoursMode(canvasContext, dates, displayWidth, true, 30);
        break;
      case DisplayMode.Hour:
        isAllowed = this.IsValidHoursMode(canvasContext, dates, displayWidth, false, 0);
        break;
      case DisplayMode.Day:
        isAllowed = this.IsValidDayMode(canvasContext, dates, displayWidth);
        break;
      //case DisplayMode.TwoDay:
      //  isAllowed = this.IsValidTwoDayMode(canvasContext, dates, displayWidth);
      //  break;
      case DisplayMode.Week:
        isAllowed = this.IsValidWeekMode(canvasContext, dates, displayWidth);
        break;
      case DisplayMode.Month:
        isAllowed = this.IsValidMonthMode(canvasContext, dates, displayWidth);
        break;
      case DisplayMode.Quarter:
        isAllowed = this.IsValidQuarterMode(canvasContext, dates, displayWidth);
        break;
      case DisplayMode.Year:
        isAllowed = this.IsValidYearMode(canvasContext, dates, displayWidth);
        break;
      case DisplayMode.FiveYear:
        isAllowed = this.IsValidYearMode(canvasContext, dates, displayWidth);
        break;
      case DisplayMode.TenYear:
        isAllowed = this.IsValidYearMode(canvasContext, dates, displayWidth);
        break;
      default:
        isAllowed = false;
        break;
    }

    return isAllowed;
  }

  private IsValidHoursMode(canvasContext: CanvasRenderingContext2D, dates: Date[], allowedSpace: number, withMinutes: boolean = true, minuteDifference: any) {
    var displaySpaceLayer1 = 0;
    var displaySpaceLayer2 = 0;

    var allowedCharacterSpacing = 18;
    var lastDate: Date = new Date(dates[0]);

    for (var i = 0; i < dates.length; i++) {
      var currentDate: Date = new Date(dates[i]);

      if (i > 0) {
        var currentDate: Date = new Date(dates[i]);

        if (withMinutes && currentDate.getMinutes() != lastDate.getMinutes() && this.isDifferenceInMinutes(currentDate, lastDate, minuteDifference)) {
          var textWidth = this.GetHourWithAMPM(currentDate.getMinutes(), currentDate.getHours(), true, true)
          var canvasTextWidth = canvasContext.measureText(textWidth).width + allowedCharacterSpacing;

          displaySpaceLayer1 += canvasTextWidth;
          lastDate = new Date(dates[i]);
        }
        else if (!withMinutes && currentDate.getHours() != lastDate.getHours()) {
          var textWidth = this.GetHourWithAMPM(currentDate.getMinutes(), currentDate.getHours(), true, false)
          var canvasTextWidth = canvasContext.measureText(textWidth).width + allowedCharacterSpacing;

          displaySpaceLayer1 += canvasTextWidth;
          lastDate = new Date(dates[i]);
        }

        if (currentDate.getDate() != lastDate.getDate()) {
          var textWidth2 = this.GetMonthDayYearFormat(currentDate.getDate(), currentDate.getMonth(), currentDate.getFullYear());
          var canvasTextWidth2 = canvasContext.measureText(textWidth2).width + allowedCharacterSpacing;

          displaySpaceLayer2 += canvasTextWidth2;
        }
      }
    }

    if (displaySpaceLayer1 <= allowedSpace && displaySpaceLayer2 <= allowedSpace) {
      return true;
    }
    else {
      return false;
    }
  }

  private IsValidDayMode(canvasContext: CanvasRenderingContext2D, dates: Date[], allowedSpace: number) {
    var displaySpaceLayer1 = 0;
    var displaySpaceLayer2 = 0;

    var allowedCharacterSpacing = 18;
    var lastDate: Date = new Date(dates[0]);

    for (var i = 0; i < dates.length; i++) {
      var currentDate: Date = new Date(dates[i]);
      if (i > 0) {
        if (currentDate.getDate() != lastDate.getDate()) {
          var textWidth = this.GetDateString(currentDate.getDate());
          var canvasTextWidth = canvasContext.measureText(textWidth).width + allowedCharacterSpacing;

          displaySpaceLayer1 += canvasTextWidth;
          lastDate = new Date(dates[i]);
        }

        if (currentDate.getMonth() != lastDate.getMonth()) {
          var textWidth2 = this.GetMonthAndYearFormat(currentDate.getMonth(), currentDate.getFullYear());
          var canvasTextWidth2 = canvasContext.measureText(textWidth2).width + allowedCharacterSpacing;

          displaySpaceLayer2 += canvasTextWidth2;
        }
      }
    }

    if (displaySpaceLayer1 <= allowedSpace && displaySpaceLayer2 <= allowedSpace) {
      return true;
    }
    else {
      return false;
    }
  }

  private IsValidTwoDayMode(canvasContext: CanvasRenderingContext2D, dates: Date[], allowedSpace: number) {
    var displaySpaceLayer1 = 0;
    var displaySpaceLayer2 = 0;

    var allowedCharacterSpacing = 18;
    var lastDate: Date = new Date(dates[0]);

    for (var i = 0; i < dates.length; i++) {
      var currentDate: Date = new Date(dates[i]);
      if (i > 0) {
        if (currentDate.getDate() != lastDate.getDate() && this.IsTwoDayDifference(currentDate, lastDate)) {
          var textWidth = this.GetDateString(currentDate.getDate());
          var canvasTextWidth = canvasContext.measureText(textWidth).width + allowedCharacterSpacing;

          displaySpaceLayer1 += canvasTextWidth;
          lastDate = new Date(dates[i]);
        }

        if (currentDate.getMonth() != lastDate.getMonth()) {
          var textWidth2 = this.GetMonthAndYearFormat(currentDate.getMonth(), currentDate.getFullYear());
          var canvasTextWidth2 = canvasContext.measureText(textWidth2).width + allowedCharacterSpacing;

          displaySpaceLayer2 += canvasTextWidth2;
        }
      }
    }

    if (displaySpaceLayer1 <= allowedSpace && displaySpaceLayer2 <= allowedSpace) {
      return true;
    }
    else {
      return false;
    }
  }

  private IsValidWeekMode(canvasContext: CanvasRenderingContext2D, dates: Date[], allowedSpace: number) {
    var displaySpaceLayer1 = 0;
    var displaySpaceLayer2 = 0;

    var allowedCharacterSpacing = 18;
    var lastDate: Date = new Date(dates[0]);

    for (var i = 0; i < dates.length; i++) {
      var currentDate: Date = new Date(dates[i]);
      if (i > 0) {
        if (currentDate.getDay() == DayOfWeek.Monday || (currentDate.getDate() - lastDate.getDate() >= 7)) {
          var textWidth = this.GetDateString(currentDate.getDate());
          var canvasTextWidth = canvasContext.measureText(textWidth).width + allowedCharacterSpacing;

          displaySpaceLayer1 += canvasTextWidth;
          lastDate = new Date(dates[i]);
        }

        if (currentDate.getMonth() != lastDate.getMonth()) {
          var textWidth2 = this.GetMonthAndYearFormat(currentDate.getMonth(), currentDate.getFullYear());
          var canvasTextWidth2 = canvasContext.measureText(textWidth2).width + allowedCharacterSpacing;

          displaySpaceLayer2 += canvasTextWidth2;
        }
      }
    }

    if (displaySpaceLayer1 <= allowedSpace && displaySpaceLayer2 <= allowedSpace) {
      return true;
    }
    else {
      return false;
    }
  }

  private IsValidMonthMode(canvasContext: CanvasRenderingContext2D, dates: Date[], allowedSpace: number) {
    var displaySpaceLayer1 = 0;
    var displaySpaceLayer2 = 0;

    var allowedCharacterSpacing = 18;
    var lastDate: Date = new Date(dates[0]);

    for (var i = 0; i < dates.length; i++) {
      var currentDate: Date = new Date(dates[i]);
      if (i > 0) {
        if (currentDate.getMonth() != lastDate.getMonth()) {
          var textWidth = (this.isFullMonth ? this.fullNameMonthArray[currentDate.getMonth()] : this.shortNameMonthArray[currentDate.getMonth()]);
          var canvasTextWidth = canvasContext.measureText(textWidth).width + allowedCharacterSpacing;

          displaySpaceLayer1 += canvasTextWidth;
          lastDate = new Date(dates[i]);
        }

        if (currentDate.getFullYear() != lastDate.getFullYear()) {
          var textWidth2 = currentDate.getFullYear().toString();
          var canvasTextWidth2 = canvasContext.measureText(textWidth2).width + allowedCharacterSpacing;

          displaySpaceLayer2 += canvasTextWidth2;
        }
      }
    }

    if (displaySpaceLayer1 <= allowedSpace && displaySpaceLayer2 <= allowedSpace) {
      return true;
    }
    else {
      return false;
    }
  }

  private IsValidQuarterMode(canvasContext: CanvasRenderingContext2D, dates: Date[], allowedSpace: number) {
    var displaySpaceLayer1 = 0;
    var displaySpaceLayer2 = 0;

    var allowedCharacterSpacing = 18;
    var lastDate: Date = new Date(dates[0]);

    for (var i = 0; i < dates.length; i++) {
      var currentDate: Date = new Date(dates[i]);
      if (i > 0) {
        if (this.GetMonthQuarterString(currentDate.getMonth()) != this.GetMonthQuarterString(lastDate.getMonth())) {
          var textWidth = this.GetMonthQuarterString(currentDate.getMonth());
          var canvasTextWidth = canvasContext.measureText(textWidth).width + allowedCharacterSpacing;

          displaySpaceLayer1 += canvasTextWidth;
          lastDate = new Date(dates[i]);
        }

        if (currentDate.getFullYear() != lastDate.getFullYear()) {
          var textWidth2 = currentDate.getFullYear().toString();
          var canvasTextWidth2 = canvasContext.measureText(textWidth2).width + allowedCharacterSpacing;

          displaySpaceLayer2 += canvasTextWidth2;
        }
      }
    }

    if (displaySpaceLayer1 <= allowedSpace && displaySpaceLayer2 <= allowedSpace) {
      return true;
    }
    else {
      return false;
    }
  }

  private IsValidYearMode(canvasContext: CanvasRenderingContext2D, dates: Date[], allowedSpace: number, yearInNumber = 1) {
    var displaySpaceLayer1 = 0;

    var allowedCharacterSpacing = 18;
    var lastDate: Date = new Date(dates[0]);
    for (var i = 0; i < dates.length; i++) {
      var currentDate: Date = new Date(dates[i]);
      if (i > 0) {
        if (currentDate.getFullYear() != lastDate.getFullYear() && (currentDate.getFullYear() - lastDate.getFullYear() >= yearInNumber)) {
          var textWidth = currentDate.getFullYear().toString();
          var canvasTextWidth = canvasContext.measureText(textWidth).width + allowedCharacterSpacing;

          displaySpaceLayer1 += canvasTextWidth;
          lastDate = new Date(dates[i]);
        }
      }
    }

    if (displaySpaceLayer1 <= allowedSpace) {
      return true;
    }
    else {
      return false;
    }
  }

  private DisplayMinutes(canvasContext: CanvasRenderingContext2D, dates: Date[], xScale: number, x0: number, y0: number, differenceInMinutes: any) {
    var xPosition = x0;
    var yPosition = y0;

    var dateDisplayArray = [];
    var startOfDateX = [];
    var endOfDateX = [];
    var lastDate: any;

    if (dates.length > 0) {
      this.startIndex = 0;
      while (this.startIndex < dates.length) {
        var firstTime = new Date(dates[this.startIndex]);

        if (firstTime.getMinutes() % differenceInMinutes != 0) {
          this.startIndex = this.startIndex + 1;
          continue;
        }
        else {
          lastDate = firstTime;
          dateDisplayArray.push(firstTime);
          startOfDateX.push(this.startIndex);
          break;
        }
      }
    }

    for (var i = this.startIndex + 1; i < dates.length; i++) {
      var thisDate = new Date(dates[i]);
      if (thisDate.getMinutes() != lastDate.getMinutes() && this.isDifferenceInMinutes(thisDate, lastDate, differenceInMinutes)) {
        endOfDateX.push(i);
        var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
        if (dateToDisplay.getHours() < 16) {
          var displayText = this.GetHourWithAMPM(dateToDisplay.getMinutes(), dateToDisplay.getHours(), true, true);
          //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
          xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
          canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 3, yPosition);

          this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
        }

        var firstTime = new Date(dates[i]);
        lastDate = firstTime;
        dateDisplayArray.push(firstTime);
        startOfDateX.push(i);
      }
    }

    if (dates.length > 0) {
      endOfDateX.push(dates.length - 1);
      var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
      var displayText = this.GetHourWithAMPM(dateToDisplay.getMinutes(), dateToDisplay.getHours(), true, true);
      //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
      xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
      canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 3, yPosition);

      this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
    }
  }

  private DisplayHours(canvasContext: CanvasRenderingContext2D, dates: Date[], xScale: number, x0: number, y0: any) {
    var xPosition = x0;
    var yPosition = y0;

    var dateDisplayArray = [];
    var startOfDateX = [];
    var endOfDateX = [];
    var lastDate: any;

    if (dates.length > 0) {
      var firstTime = new Date(dates[this.startIndex]);
      lastDate = firstTime;
      dateDisplayArray.push(firstTime);
      startOfDateX.push(this.startIndex);
    }

    for (var i = this.startIndex + 1; i < dates.length; i++) {
      var thisDate = new Date(dates[i]);

      if (thisDate.getHours() != lastDate.getHours()) {
        endOfDateX.push(i);
        var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
        if (dateToDisplay.getHours() != 9 && dateToDisplay.getHours() != 16) {
          var displayText = this.GetHourWithAMPM(dateToDisplay.getMinutes(), dateToDisplay.getHours(), true, false);
          xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;

          //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
          if (xPosition > canvasContext.measureText(displayText).width) {
            canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);
            this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
          }
        }

        var firstTime = new Date(dates[i]);
        lastDate = firstTime;
        dateDisplayArray.push(firstTime);
        startOfDateX.push(i);
      }
    }

    if (dates.length > 0) {
      endOfDateX.push(dates.length - 1);
      var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
      var displayText = this.GetHourWithAMPM(dateToDisplay.getMinutes(), dateToDisplay.getHours(), true, false);
      // xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
      xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
      canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);
      this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
    }
  }

  private DisplayDays(canvasContext: CanvasRenderingContext2D, dates: Date[], xScale: number, x0: number, y0: any) {
    var xPosition = x0;
    var yPosition = y0;

    var dateDisplayArray = [];
    var startOfDateX = [];
    var endOfDateX = [];
    var lastDate: any;

    if (dates.length > 0) {
      var firstTime = new Date(dates[this.startIndex]);
      lastDate = firstTime;
      dateDisplayArray.push(firstTime);
      startOfDateX.push(this.startIndex);
    }

    for (var i = this.startIndex + 1; i < dates.length; i++) {
      var thisDate = new Date(dates[i]);
      if (thisDate.getDate() != lastDate.getDate()) {
        endOfDateX.push(i);
        var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
        var displayText = this.GetDateString(dateToDisplay.getDate());
        //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
        xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
        if (xPosition > canvasContext.measureText(displayText).width) {
          canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);
          this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
        }

        var firstTime = new Date(dates[i]);
        lastDate = firstTime;
        dateDisplayArray.push(firstTime);
        startOfDateX.push(i);
      }
    }

    if (dates.length > 0) {
      endOfDateX.push(dates.length - 1);
      var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
      var displayText = this.GetDateString(dateToDisplay.getDate());
      xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
      xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
      canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);

      this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
    }
  }

  private DisplayTwoDays(canvasContext: CanvasRenderingContext2D, dates: Date[], xScale: number, x0: number, y0: any) {
    var xPosition = x0;
    var yPosition = y0;

    var dateDisplayArray = [];
    var startOfDateX = [];
    var endOfDateX = [];
    var lastDate: any;

    if (dates.length > 0) {
      var firstTime = new Date(dates[this.startIndex]);
      lastDate = firstTime;
      dateDisplayArray.push(firstTime);
      startOfDateX.push(this.startIndex);
    }

    for (var i = this.startIndex + 1; i < dates.length; i++) {
      var thisDate = new Date(dates[i]);
      if (thisDate.getDate() != lastDate.getDate() && this.IsTwoDayDifference(thisDate, lastDate)) {
        endOfDateX.push(i);
        var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
        var displayText = this.GetDateString(dateToDisplay.getDate());
        //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
        xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
        if (xPosition > canvasContext.measureText(displayText).width) {
          canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);
          this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
        }

        var firstTime = new Date(dates[i]);
        lastDate = firstTime;
        dateDisplayArray.push(firstTime);
        startOfDateX.push(i);
      }
    }

    if (dates.length > 0) {
      endOfDateX.push(dates.length - 1);
      var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
      var displayText = this.GetDateString(dateToDisplay.getDate());
      // xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
      xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
      canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);
      this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
    }
  }

  private DisplayWeeks(canvasContext: CanvasRenderingContext2D, dates: Date[], xScale: number, x0: number, y0: any) {
    var xPosition = x0;
    var yPosition = y0;

    var dateDisplayArray = [];
    var startOfDateX = [];
    var endOfDateX = [];
    var lastDate;

    if (dates.length > 0) {
      for (var i = this.startIndex; i < dates.length; i++) {
        var firstTime = new Date(dates[i]);

        if (firstTime.getDay() == DayOfWeek.Monday) {
          lastDate = firstTime;
          dateDisplayArray.push(firstTime);
          startOfDateX.push(this.startIndex);
          break;
        }
      }
    }

    for (var i = this.startIndex + 1; i < dates.length; i++) {
      var thisDate = new Date(dates[i]);
      var lastWeekDate = new Date(dateDisplayArray[dateDisplayArray.length - 1]);

      if ((thisDate.getDay() == DayOfWeek.Monday || (thisDate.getDate() - lastWeekDate.getDate() >= 7))) {
        var previousOneDate = new Date(dateDisplayArray[endOfDateX.length - 1]);
        endOfDateX.push(i);
        var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);

        if (previousOneDate.getDate() != dateToDisplay.getDate()) {
          var displayText = this.GetDateString(dateToDisplay.getDate());
          //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
          xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
          if (xPosition > canvasContext.measureText(displayText).width) {
            canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);
            this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
          }
        }

        var firstTime = new Date(dates[i]);
        lastDate = firstTime;
        dateDisplayArray.push(firstTime);
        startOfDateX.push(i);
      }
    }

    if (dates.length > 0) {
      var previousOneDate = new Date(dateDisplayArray[endOfDateX.length - 1]);

      endOfDateX.push(dates.length - 1);
      var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
      if (previousOneDate.getDate() != dateToDisplay.getDate()) {
        var displayText = this.GetDateString(dateToDisplay.getDate());
        // xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
        xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
        canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);

        this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
      }
    }
  }

  private DisplayMonths(canvasContext: CanvasRenderingContext2D, dates: Date[], xScale: number, x0: number, y0: any) {
    var xPosition = x0;
    var yPosition = y0;

    var dateDisplayArray = [];
    var startOfDateX = [];
    var endOfDateX = [];
    var lastDate: any;

    if (dates.length > 0) {
      var firstTime = new Date(dates[this.startIndex]);
      lastDate = firstTime;
      dateDisplayArray.push(firstTime);
      startOfDateX.push(this.startIndex);
    }

    for (var i = this.startIndex + 1; i < dates.length; i++) {
      var thisDate = new Date(dates[i]) as any;

      if (thisDate.getMonth() != lastDate.getMonth()) {
        endOfDateX.push(i);
        var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
        var displayText = (this.isFullMonth ? this.fullNameMonthArray[dateToDisplay.getMonth()] : this.shortNameMonthArray[dateToDisplay.getMonth()]);
        //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
        xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
        if (xPosition > canvasContext.measureText(displayText).width) {
          canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);
          this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
        }

        var firstTime = new Date(dates[i]);
        lastDate = firstTime;
        dateDisplayArray.push(firstTime);
        startOfDateX.push(i);
      }
    }

    if (dates.length > 0) {
      endOfDateX.push(dates.length - 1);

      var displayText = (this.isFullMonth ? this.fullNameMonthArray[thisDate.getMonth()] : this.shortNameMonthArray[thisDate.getMonth()]);
      //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
      xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
      canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);

      this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
    }
  }

  private DisplayQuarters(canvasContext: CanvasRenderingContext2D, dates: Date[], xScale: number, x0: number, y0: any) {
    var xPosition = x0;
    var yPosition = y0;

    var dateDisplayArray = [];
    var startOfDateX = [];
    var endOfDateX = [];
    var lastDate: any;

    if (dates.length > 0) {
      var firstTime = new Date(dates[this.startIndex]);
      lastDate = firstTime;
      dateDisplayArray.push(firstTime);
      startOfDateX.push(this.startIndex);
    }

    for (var i = this.startIndex + 1; i < dates.length; i++) {
      var thisDate = new Date(dates[i]);
      if (thisDate.getMonth() != lastDate.getMonth() && !this.IsSameQuarter(thisDate, lastDate)) {
        endOfDateX.push(i);
        var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
        var displayText = this.GetMonthQuarterString(dateToDisplay.getMonth());
        //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
        xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
        if (xPosition > canvasContext.measureText(displayText).width) {
          canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);
          this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
        }

        var firstTime = new Date(dates[i]);
        lastDate = firstTime;
        dateDisplayArray.push(firstTime);
        startOfDateX.push(i);
      }
    }

    if (dates.length > 0) {
      endOfDateX.push(dates.length - 1);

      var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
      var displayText = this.GetMonthQuarterString(dateToDisplay.getMonth());
      // xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
      xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;

      canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);
      this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
    }
  }

  public DisplayMonthDayYear(canvasContext: CanvasRenderingContext2D, dates: Date[], xScale: number, x0: number, y0: any) {
    var xPosition = x0;
    var yPosition = y0;

    var dateDisplayArray = [];
    var startOfDateX = [];
    var endOfDateX = [];
    var lastDate: any;

    if (dates.length > 0) {
      var firstTime = new Date(dates[this.startIndex]);
      lastDate = firstTime;
      dateDisplayArray.push(firstTime);
      startOfDateX.push(this.startIndex);
    }

    for (var i = this.startIndex + 1; i < dates.length; i++) {
      var thisDate = new Date(dates[i]);
      if (thisDate.getDate() != lastDate.getDate()) {
        endOfDateX.push(i);
        var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
        var displayText = this.GetMonthDayYearFormat(dateToDisplay.getDate(), dateToDisplay.getMonth(), dateToDisplay.getFullYear());
        //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
        xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
        if (xPosition > canvasContext.measureText(displayText).width) {
          canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);
        }

        var firstTime = new Date(dates[i]);
        lastDate = firstTime;
        dateDisplayArray.push(firstTime);
        startOfDateX.push(i);
      }
    }

    if (dates.length > 0) {
      endOfDateX.push(dates.length - 1);
      var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
      var displayText = this.GetMonthDayYearFormat(dateToDisplay.getDate(), dateToDisplay.getMonth(), dateToDisplay.getFullYear());
      //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
      xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
      canvasContext.fillText(displayText, x0 + xPosition, yPosition);
    }
  }

  private DisplayMonthYear(canvasContext: CanvasRenderingContext2D, dates: Date[], xScale: number, x0: number, y0: any) {
    var xPosition = x0;
    var yPosition = y0;

    var dateDisplayArray = [];
    var startOfDateX = [];
    var endOfDateX = [];
    var lastDate: any;

    if (dates.length > 0) {
      var firstTime = new Date(dates[this.startIndex]);
      lastDate = firstTime;
      dateDisplayArray.push(firstTime);
      startOfDateX.push(this.startIndex);
    }

    for (var i = this.startIndex + 1; i < dates.length; i++) {
      var thisDate = new Date(dates[i]);
      if (thisDate.getMonth() != lastDate.getMonth()) {
        endOfDateX.push(i);

        var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
        var displayText = this.GetMonthAndYearFormat(dateToDisplay.getMonth(), dateToDisplay.getFullYear());
        //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
        xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
        if (xPosition > canvasContext.measureText(displayText).width)
          canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);

        var firstTime = new Date(dates[i]);
        lastDate = firstTime;
        dateDisplayArray.push(firstTime);
        startOfDateX.push(i);
      }
    }

    if (dates.length > 0) {
      endOfDateX.push(dates.length - 1);
      var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
      var displayText = this.GetMonthAndYearFormat(dateToDisplay.getMonth(), dateToDisplay.getFullYear());
      //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
      xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
      canvasContext.fillText(displayText, x0 + xPosition, yPosition);
    }
  }

  private DisplayFullYear(canvasContext: CanvasRenderingContext2D, dates: Date[], xScale: number, x0: number, y0: any, isFirstLine = false, yearInDiff = 1) {
    var xPosition = x0;
    var yPosition = y0;

    var dateDisplayArray = [];
    var startOfDateX = [];
    var endOfDateX = [];
    var lastDate: any;

    if (dates.length > 0) {
      var firstTime = new Date(dates[this.startIndex]);
      lastDate = firstTime;
      dateDisplayArray.push(firstTime);
      startOfDateX.push(this.startIndex);
    }

    for (var i = this.startIndex + 1; i < dates.length; i++) {
      var thisDate = new Date(dates[i]);
      if (thisDate.getFullYear() != lastDate.getFullYear() && (thisDate.getFullYear() - lastDate.getFullYear() >= yearInDiff)) {
        endOfDateX.push(i);

        var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
        var displayText = dateToDisplay.getFullYear().toString();
        // xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
        xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
        if (xPosition > canvasContext.measureText(displayText).width) {
          canvasContext.fillText(displayText, x0 + xPosition - canvasContext.measureText(displayText).width / 2, yPosition);

          if (isFirstLine) {
            this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
          }
        }

        var firstTime = new Date(dates[i]);
        lastDate = firstTime;
        dateDisplayArray.push(firstTime);
        startOfDateX.push(i);
      }
    }

    if (dates.length > 0) {
      endOfDateX.push(dates.length - 1);
      var dateToDisplay = new Date(dateDisplayArray[endOfDateX.length - 1]);
      var displayText = dateToDisplay.getFullYear().toString();

      //xPosition = Math.round((endOfDateX[endOfDateX.length - 1] + startOfDateX[endOfDateX.length - 1]) / 2) * xScale;
      xPosition = Math.round(startOfDateX[endOfDateX.length - 1]) * xScale;
      canvasContext.fillText(displayText, x0 + xPosition, yPosition);

      if (isFirstLine) {
        this.visibleDatesTimelinePositionArray.push(x0 + xPosition);
      }
    }
  }

  public DrawHorizontalDates(displayMode: DisplayMode, canvasContext: CanvasRenderingContext2D, dates: Date[], xScale: number, y0: number, x0: number) {
    var yForLine2 = y0 + 15;

    canvasContext.beginPath();
    canvasContext.font = this.textFont;

    canvasContext.strokeStyle = this.strokeStyle;
    canvasContext.fillStyle = this.fillStyle;

    switch (displayMode) {
      case DisplayMode.Minute:
        this.DisplayMinutes(canvasContext, dates, xScale, x0, y0, 1);
        this.DisplayMonthDayYear(canvasContext, dates, xScale, x0, yForLine2);
        break;
      case DisplayMode.FiveMinute:
        this.DisplayMinutes(canvasContext, dates, xScale, x0, y0, 5);
        this.DisplayMonthDayYear(canvasContext, dates, xScale, x0, yForLine2);
        break;
      case DisplayMode.TenMinute:
        this.DisplayMinutes(canvasContext, dates, xScale, x0, y0, 10);
        this.DisplayMonthDayYear(canvasContext, dates, xScale, x0, yForLine2);
        break;
      case DisplayMode.FifteenMinute:
        this.DisplayMinutes(canvasContext, dates, xScale, x0, y0, 15);
        this.DisplayMonthDayYear(canvasContext, dates, xScale, x0, yForLine2);
        break;
      case DisplayMode.ThirtyMinute:
        this.DisplayMinutes(canvasContext, dates, xScale, x0, y0, 30);
        this.DisplayMonthDayYear(canvasContext, dates, xScale, x0, yForLine2);
        break;
      case DisplayMode.Hour:
        this.DisplayHours(canvasContext, dates, xScale, x0, y0);
        this.DisplayMonthDayYear(canvasContext, dates, xScale, x0, yForLine2);
        break;
      case DisplayMode.Day:
        this.DisplayDays(canvasContext, dates, xScale, x0, y0);
        this.DisplayMonthYear(canvasContext, dates, xScale, x0, yForLine2);
        break;
      //case DisplayMode.TwoDay:
      //  this.DisplayTwoDays(canvasContext, dates, xScale, x0, y0);
      //  this.DisplayMonthYear(canvasContext, dates, xScale, x0, yForLine2);
      //  break;
      case DisplayMode.Week:
        this.DisplayWeeks(canvasContext, dates, xScale, x0, y0);
        this.DisplayMonthYear(canvasContext, dates, xScale, x0, yForLine2);
        break;
      case DisplayMode.Month:
        this.DisplayMonths(canvasContext, dates, xScale, x0, y0);
        this.DisplayFullYear(canvasContext, dates, xScale, x0, yForLine2);
        break;
      case DisplayMode.Quarter:
        this.DisplayQuarters(canvasContext, dates, xScale, x0, y0);
        this.DisplayFullYear(canvasContext, dates, xScale, x0, yForLine2);
        break;
      case DisplayMode.Year:
        this.DisplayFullYear(canvasContext, dates, xScale, x0, y0, true, 1);
        break;
      case DisplayMode.FiveYear:
        this.DisplayFullYear(canvasContext, dates, xScale, x0, y0, true, 5);
        break;
      case DisplayMode.TenYear:
        this.DisplayFullYear(canvasContext, dates, xScale, x0, y0, true, 10);
        break;
      default:
        this.DisplayFullYear(canvasContext, dates, xScale, x0, y0, true, 10);
        break;
    }

    canvasContext.stroke();
  }
  public GetRefinedURL(URL: any) {
    var RefindURL = URL;
    RefindURL = URL.replace(/\W+/g, '-');//URL.split('?').join(' ').split('.').join(' ').split(':').join(' ').split(';').join(' ').split('!').join(' ').split('$').join(' ').split('#').join(' ').split('@').join(' ').split('*').join(' ').split('+').join(' ').split('=').join(' ').split('(').join(' ').split(')').join(' ').split('{').join(' ').split('}').join(' ').split('[').join(' ').split(']').join(' ').split('`').join(' ').replace(/\s+/g, '-');
    return RefindURL;
  }

  public ApplyVigsearchClasses() {
    document.getElementById('changetheme')!.setAttribute('class', 'oa-changetheme');
    document.getElementById('topmenudate')!.setAttribute('class', 'oa-menutlis');
    document.getElementById('topmenuhelp')!.setAttribute('class', 'oa-menutlishelp');
    document.getElementById('topmenuseperate')!.setAttribute('class', 'topmenuline2');
    document.getElementById('topmenuseperate2')!.setAttribute('class', 'topmenuline3');
    document.getElementById('topmenuseperate1')!.setAttribute('class', 'topmenuline');
    document.getElementById('topmainmenu')!.setAttribute('class', 'oa-menu');
  }

  public CalculateProRatedValue(TotalDay: any, Days: any, TotalAmount: any, DecimalPlaces: any) {
    var amount = 0;
    if (TotalDay != 0)
      amount = (TotalAmount / TotalDay) * Days;
    return amount.toFixed(DecimalPlaces);
  }

  public GetMaskedInvestingAccount(p_strNumber: any, LastNumbers: any, HowLong: any) {
    var l_strAccNumber = this.MaskedInvestingAccount(p_strNumber, LastNumbers);
    if (HowLong > 0) {
      l_strAccNumber = l_strAccNumber.substr(l_strAccNumber.length - HowLong, HowLong);
    }
    return l_strAccNumber;
  }

  public MaskedInvestingAccount(p_strNumber: any, LastNumbers: any) {
    var l_strCCNumber = "";
    for (var i = 0, len = p_strNumber.length; i < len; i++) {
      if (i <= (p_strNumber.length - (LastNumbers + 1)))
        l_strCCNumber += "*";
      else
        l_strCCNumber += p_strNumber[i];
    }

    return l_strCCNumber;
  }

  public CheckIfCCHasMaskedValue(p_strCardNumber: any) {
    var l_boolHasMaskedValue = false;
    for (var i = 0, len = p_strCardNumber.length; i < len; i++) {
      if (p_strCardNumber[i] == "*")
        l_boolHasMaskedValue = true;
    }

    return l_boolHasMaskedValue;
  }

  public GetFileIconToDisplay(FilePath: any) {
    var IconURL = 'Default/img/NoImage.jpg';
    var parts = FilePath.split('.');
    var ext = parts[parts.length - 1];
    switch (ext.toLowerCase()) {
      case 'jpeg':
        IconURL = FilePath;
        break;
      case 'jpg':
        IconURL = FilePath;
        break;
      case 'png':
        IconURL = FilePath;
        break;
      case 'tif':
        IconURL = FilePath;
        break;
      case 'tiff':
        IconURL = FilePath;
        break;
      case 'pdf':
        IconURL = 'Default/img/pdficon.jpg';
        break;
      case 'docx':
        IconURL = 'Default/img/Word.jpg';
        break;
      case 'doc':
        IconURL = 'Default/img/Word.jpg';
        break;
      case 'xlsx':
        IconURL = 'Default/img/xls_icon.png';
        break;
      case 'xls':
        IconURL = 'Default/img/xls_icon.png';
        break;
      case 'pptx':
        IconURL = 'Default/img/ppt.png';
        break;
      case 'ppt':
        IconURL = 'Default/img/ppt.png';
        break;
      case 'wav':
        IconURL = 'Default/img/audio.png';
        break;
      case 'mid':
        IconURL = 'Default/img/audio.png';
        break;
      case 'midi':
        IconURL = 'Default/img/audio.png';
        break;
      case 'wma':
        IconURL = 'Default/img/audio.png';
        break;
      case 'mp3':
        IconURL = 'Default/img/audio.png';
        break;
      case 'ogg':
        IconURL = 'Default/img/audio.png';
        break;
      case 'rma':
        IconURL = 'Default/img/audio.png';
        break;
      case 'au':
        IconURL = 'Default/img/audio.png';
        break;
      case 'ea':
        IconURL = 'Default/img/audio.png';
        break;
      case 'avi':
        IconURL = 'Default/img/video.png';
        break;
      case 'mp4':
        IconURL = 'Default/img/video.png';
        break;
      case 'divx':
        IconURL = 'Default/img/video.png';
        break;
      case 'wmv':
        IconURL = 'Default/img/video.png';
        break;
      case 'mkv':
        IconURL = 'Default/img/video.png';
        break;
      case 'flv':
        IconURL = 'Default/img/video.png';
        break;
      case 'avi':
        IconURL = 'Default/img/video.png';
        break;
      case 'vob':
        IconURL = 'Default/img/video.png';
        break;
      case 'ogv':
        IconURL = 'Default/img/video.png';
        break;
      case 'wmv':
        IconURL = 'Default/img/video.png';
        break;
      case 'm4v':
        IconURL = 'Default/img/video.png';
        break;
      case 'mov':
        IconURL = 'Default/img/video.png';
        break;
      case 'wmv':
        IconURL = 'Default/img/video.png';
        break;
      default:
        IconURL = 'Default/img/NoImage.jpg';
    }

    return IconURL;
  }

  public GetDataSelectorCalculatedValues(DataSelector1: any, DataSelector2: any, Operator: any, OperatorSeparator: any, DecimalPlaces: any) {
    var ResultData = {} as any;
    ResultData.Value = -1;
    ResultData.TypeOfData = -1;
    try {
      var Date1Value: any;
      var Date2Value: any;
      if ((DataSelector1.TypeOfData == 1 || DataSelector2.TypeOfData == 1) || (DataSelector1.SelectorType == 5 || DataSelector2.SelectorType == 5)) {
        if (DataSelector1.TypeOfData == 1)
          Date1Value = parseInt(DataSelector1.DataValueInt);
        if (DataSelector1.TypeOfData == 5)
          Date1Value = parseInt(DataSelector1.DataValueDecimal);
        if (DataSelector2.TypeOfData == 1)
          Date2Value = parseInt(DataSelector2.DataValueInt);
        if (DataSelector2.TypeOfData == 5)
          Date2Value = parseInt(DataSelector2.DataValueDecimal);
        if (DataSelector1.SelectorType == 5)
          Date1Value = parseInt(DataSelector1.StaticValue);
        if (DataSelector2.SelectorType == 5)
          Date2Value = parseInt(DataSelector2.StaticValue);
        var Value = 0;
        if (Operator == 10) {
          Value = parseInt(Date1Value + Date2Value);
          ResultData.Value = Value;
          ResultData.TypeOfData = 1;
        }
        else if (Operator == 15) {
          Value = Date1Value - Date2Value;
          ResultData.Value = Value;
          ResultData.TypeOfData = 1;
        }
        else if (Operator == 20) {
          Value = Date1Value * Date2Value;
          ResultData.Value = Value;
          ResultData.TypeOfData = 1;
        }
        else if (Operator == 25) {
          Value = parseFloat((parseFloat(Date1Value) / parseFloat(Date2Value)).toFixed(DecimalPlaces));
          ResultData.Value = Value;
          ResultData.TypeOfData = 5;
        } else if (Operator == 30) {
          ResultData.Value = Date1Value > Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 35) {
          ResultData.Value = Date1Value < Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 40) {
          ResultData.Value = Date1Value >= Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 45) {
          ResultData.Value = Date1Value <= Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 50) {
          ResultData.Value = Date1Value == Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 55) {
          ResultData.Value = Date1Value != Date2Value;
          ResultData.TypeOfData = 4;
        }
      }
      else if ((DataSelector1.TypeOfData == 5 || DataSelector2.TypeOfData == 5) || (DataSelector1.SelectorType == 5 || DataSelector2.SelectorType == 5)) {
        if (DataSelector1.TypeOfData == 1)
          Date1Value = parseFloat(DataSelector1.DataValueInt);
        if (DataSelector1.TypeOfData == 5)
          Date1Value = parseFloat(DataSelector1.DataValueDecimal);
        if (DataSelector2.TypeOfData == 1)
          Date2Value = parseFloat(DataSelector2.DataValueInt);
        if (DataSelector2.TypeOfData == 5)
          Date2Value = parseFloat(DataSelector2.DataValueDecimal);
        if (DataSelector1.SelectorType == 5)
          Date1Value = parseFloat(DataSelector1.StaticValue);
        if (DataSelector2.SelectorType == 5)
          Date2Value = parseFloat(DataSelector2.StaticValue);

        var Value = 0;
        if (Operator == 10) {
          Value = Date1Value + Date2Value;
          ResultData.Value = Value;
          ResultData.TypeOfData = 5;
        }
        else if (Operator == 15) {
          Value = parseFloat((Date1Value - Date2Value).toFixed(DecimalPlaces));
          ResultData.Value = Value;
          ResultData.TypeOfData = 5;
        }
        else if (Operator == 20) {
          Value = parseFloat((parseFloat(Date1Value) * parseFloat(Date2Value)).toFixed(DecimalPlaces));
          ResultData.Value = Value;
          ResultData.TypeOfData = 5;
        }
        else if (Operator == 25) {
          Value = parseFloat((parseFloat(Date1Value) / parseFloat(Date2Value)).toFixed(DecimalPlaces));
          ResultData.Value = Value;
          ResultData.TypeOfData = 5;
        } else if (Operator == 30) {
          ResultData.Value = Date1Value > Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 35) {
          ResultData.Value = Date1Value < Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 40) {
          ResultData.Value = Date1Value >= Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 45) {
          ResultData.Value = Date1Value <= Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 50) {
          ResultData.Value = Date1Value == Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 55) {
          ResultData.Value = Date1Value != Date2Value;
          ResultData.TypeOfData = 4;
        }
      }
      else if ((DataSelector1.TypeOfData == 2 && DataSelector2.TypeOfData == 2) || (DataSelector1.SelectorType == 5 || DataSelector2.SelectorType == 5)) {
        Date1Value = DataSelector1.DataValueNvarchar;
        Date2Value = DataSelector2.DataValueNvarchar;

        if (Operator == 1) {
          if (OperatorSeparator == ' ') {
            OperatorSeparator = '';
            ResultData.Value = Date1Value + OperatorSeparator + Date2Value;
          } else {
            ResultData.Value = Date1Value + OperatorSeparator + Date2Value;
          }
          ResultData.TypeOfData = 2;
        } else if (Operator == 30) {
          ResultData.Value = Date1Value > Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 35) {
          ResultData.Value = Date1Value < Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 40) {
          ResultData.Value = Date1Value >= Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 45) {
          ResultData.Value = Date1Value <= Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 50) {
          ResultData.Value = Date1Value == Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 55) {
          ResultData.Value = Date1Value != Date2Value;
          ResultData.TypeOfData = 4;
        }
      }
      else if ((DataSelector1.TypeOfData == 3 || DataSelector2.TypeOfData == 3) || (DataSelector1.SelectorType == 5 || DataSelector2.SelectorType == 5)) {
        var Date1Value = DataSelector1.DataValueDateTime.split('-').join('/');
        var Date2Value = DataSelector2.DataValueDateTime.split('-').join('/');
        if (Operator == 1) {
          if (OperatorSeparator == ' ') {
            OperatorSeparator = '';
            ResultData.Value = Date1Value + OperatorSeparator + Date2Value;
          } else {
            ResultData.Value = Date1Value + OperatorSeparator + Date2Value;
          }
          ResultData.TypeOfData = 2;
        } else if (Operator == 30) {
          ResultData.Value = Date1Value > Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 35) {
          ResultData.Value = Date1Value < Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 40) {
          ResultData.Value = Date1Value >= Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 45) {
          ResultData.Value = Date1Value <= Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 50) {
          ResultData.Value = Date1Value == Date2Value;
          ResultData.TypeOfData = 4;
        } else if (Operator == 55) {
          ResultData.Value = Date1Value != Date2Value;
          ResultData.TypeOfData = 4;
        } else {
          var Value = 0;
          Value = this.getDifferenceInDays(new Date(Date1Value), new Date(Date2Value));
          ResultData.Value = Value;
          ResultData.TypeOfData = 1;
        }
      }
    }
    catch (e) {
      ResultData.Value = -1;
      ResultData.TypeOfData = -1;
    }
    return ResultData;
  }

  public getDifferenceInYears(date1: any, date2: any) {
    const diffInMs = Math.abs(date2 - date1);
    return Math.round(diffInMs / (1000 * 60 * 60 * 24 * 365.25));
  }

  public getDifferenceInDays(date1: any, date2: any) {
    const diffInMs = Math.abs(date2 - date1);
    return Math.round(diffInMs / (1000 * 60 * 60 * 24));
  }

  public getDifferenceInHours(date1: any, date2: any) {
    const diffInMs = Math.abs(date2 - date1);
    return Math.round(diffInMs / (1000 * 60 * 60));
  }

  public getDifferenceInMinutes(date1: any, date2: any) {
    const diffInMs = Math.abs(date2 - date1);
    return Math.round(diffInMs / (1000 * 60));
  }

  public getDifferenceInSeconds(date1: any, date2: any) {
    const diffInMs = Math.abs(date2 - date1);
    return Math.round(diffInMs / 1000);
  }
  public GetUnreadMessages() {
    this.http.get(this.baseUrl + 'api/Conversation/GetUnreadMessages').subscribe(result => {
      localStorage.setItem('count', result.toString())

      //localStorage.setItem("count", this.TotalUnreadMessages.toString());
    }, error => console.error(error));
  }

  public ConvertDateToETFormat(p_oDate: any) {
    var ESTFormat;
    let DateAccordingToET = new Date(p_oDate).toLocaleString("en-US", { timeZone: "America/New_York" });
    ESTFormat = new Date(DateAccordingToET);
    return ESTFormat;
  }

  public ConvertDateToGBFormat(p_oDate: any) {
    var ESTFormat;
    let DateAccordingToET = new Date(p_oDate).toLocaleString("en-GB", { timeZone: "UTC" });
    ESTFormat = new Date(DateAccordingToET);
    return ESTFormat;
  }

  public ConvertDateToDashesAndTimeFormat(p_oDate: Date) {
    var FormattedDate = "";
    FormattedDate = p_oDate.getFullYear() + "-" + ("0" + (p_oDate.getMonth() + 1)).toString().slice(-2) + "-" + ("0" + p_oDate.getDate()).toString().slice(-2) + "T" + ("0" + p_oDate.getHours()).toString().slice(-2) + ":" + ("0" + p_oDate.getMinutes()).toString().slice(-2) + ":" + ("0" + p_oDate.getSeconds()).toString().slice(-2);
    return FormattedDate;
  }

  public GetDifferenceBetweenTwoDatesInSeconds(p_oFirstDate: any, p_oSecondDate: any) {
    var diff = Math.abs(new Date(p_oFirstDate).getTime() - new Date(p_oSecondDate).getTime());
    var diffDays = diff / (1000);
    return diffDays;
  }

  public GetDifferenceBetweenTwoDatesInMinutes(p_oFirstDate: any, p_oSecondDate: any) {
    var diff = Math.abs(new Date(p_oFirstDate).getTime() - new Date(p_oSecondDate).getTime());
    var diffDays = diff / (1000 * 60);
    return diffDays;
  }

  public Encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  }
  public Decrypt(value: string) {
    return CryptoJS.AES.decrypt(value, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public IsDateBetweenTwoDates(p_oStartDate, p_oToCheckDate, p_oEndDate) {
    p_oStartDate = new Date(p_oStartDate);
    p_oStartDate = p_oStartDate.setHours(0, 0, 0, 0);
    p_oToCheckDate = new Date(p_oToCheckDate);
    p_oToCheckDate = p_oToCheckDate.setHours(0, 0, 0, 0);
    p_oEndDate = new Date(p_oEndDate);
    p_oEndDate = p_oEndDate.setHours(0, 0, 0, 0);
    if (p_oStartDate <= p_oToCheckDate && p_oToCheckDate <= p_oEndDate) {
      return true;
    }
    else
      return false;
  }

  public IsDateTimeBetweenTwoDates(p_oStartDate, p_oToCheckDate, p_oEndDate) {
    p_oStartDate = new Date(p_oStartDate);
    //p_oStartDate = p_oStartDate.setHours(0, 0, 0, 0);
    p_oToCheckDate = new Date(p_oToCheckDate);
    //p_oToCheckDate = p_oToCheckDate.setHours(0, 0, 0, 0);
    p_oEndDate = new Date(p_oEndDate);
    //p_oEndDate = p_oEndDate.setHours(0, 0, 0, 0);
    if (p_oStartDate <= p_oToCheckDate && p_oToCheckDate <= p_oEndDate) {
      return true;
    }
    else
      return false;
  }

  //public FormatDateInUKFormat(p_oDate) {
  //  return this.datepipe.transform(p_oDate, 'dd/MM/yyyy');
  //}


  public ConvertDateToSlashes(p_oDate) {
    let latest_date = this.datepipe.transform(p_oDate, 'MM/dd/yyyy');
    return latest_date;
  }

  public addDays(date, daysToAdd) {
    date = new Date(date);
    var _24HoursInMilliseconds = 86400000;
    return new Date(date.getTime() + daysToAdd * _24HoursInMilliseconds);
  }

  public addMinutes(date, MinutesToAdd) {
    date = new Date(date);
    var _24HoursInMilliseconds = 60000;
    return new Date(date.getTime() + MinutesToAdd * _24HoursInMilliseconds);
  }

  public numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  public SetMasterAnimationV2(p_boolShow) {
    if (p_boolShow)
      (<HTMLInputElement>document.getElementById('loader')).style.display = 'flex';
    else
      (<HTMLInputElement>document.getElementById('loader')).style.display = 'none';
  }


  public DownloadDataFile(filename, dataUrl) {

    // Construct the 'a' element
    var link = document.createElement("a");
    link.download = filename;
    link.target = "_blank";

    // Construct the URI
    link.href = dataUrl;
    document.body.appendChild(link);
    link.click();

    // Cleanup the DOM
    document.body.removeChild(link);
  }

  public SetMetaData(PageID) {

    let data = {} as any;
    data.PageID = PageID;
    this.http.post(this.BaseURL + 'api/PTStaticPage/GetStaticPageDataByPageID', data).subscribe(result => {

      var l_arrResponse = result[0] as any;
      var PageTitle = l_arrResponse[0].MetaTitle;
      var PageDescription = l_arrResponse[0].MetaDescription;
      var PageKeyWords = l_arrResponse[0].MetaKeyWords;
      this.titleService.setTitle(PageTitle);
      this.meta.updateTag({ name: 'description', content: PageDescription });
      this.meta.updateTag({ name: 'keyword', content: PageKeyWords });

      //var l_image = "https://www.finestvan.co.uk/assets/img/movers/logo.png";

      //this.meta.updateTag({ name: 'twitter:title', content: PageTitle });
      //this.meta.updateTag({ name: 'twitter:description', content: PageDescription });
      //this.meta.updateTag({ name: 'twitter:image', content: l_image });

      //this.meta.updateTag({ property: 'og:title', content: PageTitle });
      //this.meta.updateTag({ property: 'og:description', content: PageDescription });
      //this.meta.updateTag({ property: 'og:image', content: l_image });

    }, error => console.error(error));
  }

  public ScrollToTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }


  public IsFileSizeLesserThanAlowedSizeMB(OrignalFileSize, p_intAllowedSizeType, p_intAllowedSize) {
    var IsValidSize = false;
    var l_decSize = this.FileFormatAndSize(OrignalFileSize);
    var SizeCharacters = l_decSize.substr(l_decSize.length - 2);
    l_decSize = parseFloat(l_decSize.slice(0, -2));
    if ((l_decSize <= p_intAllowedSize && SizeCharacters == p_intAllowedSizeType) || SizeCharacters == "KB" || SizeCharacters == "es" || SizeCharacters == "te") {
      IsValidSize = true;
    }
    return IsValidSize;
  }

  public FileFormatAndSize(bytes) {
    if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + "GB"; }
    else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + "MB"; }
    else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + "KB"; }
    else if (bytes > 1) { bytes = bytes + "bytes"; }
    else if (bytes == 1) { bytes = bytes + "byte"; }
    else { bytes = "0 bytes"; }
    return bytes;
  }

  public RemoveSpecialCharactersFromFileName(FileName: string) {
    var IsFileNameValid = true;
    var l_Extention = FileName.substr(FileName.lastIndexOf('.'));
    FileName = FileName.slice(0, FileName.lastIndexOf('.'));
    var FileNameWithotSpecialCharacters = FileName.replace(/[^a-zA-Z0-9]/g, "");
    if (FileNameWithotSpecialCharacters == '') {
      IsFileNameValid = false;
    }
    return FileNameWithotSpecialCharacters + l_Extention;
  }

  public AjaxLoginCheckLoginClientData() {
    let data = {} as any;
    this.http.post('api/SiteUser/AjaxLoginCheckLoginClientData', data).subscribe((result) => {
      var Result = [] as any;
      Result = result;
      var ClientData = Result[0] as any;
      if (ClientData && ClientData.length > 0) {
        this.ClientInfo = ClientData[0];
      }

    },
      (error) => console.error(error));
  }


  public CheckPagePermissions() {
    var ThisURLComplete = window.location.href;

    if (this.UserRoleActual == 'SemiAdmin') {
      var PageFound = false;
      for (var i = 0; i < this.OnlyMainAdminPages.length; i++) {
        if ((ThisURLComplete.toLowerCase()).indexOf(this.OnlyMainAdminPages[0].toString()) > -1) {
          PageFound = true;
        }
      }
      if (PageFound) {
        window.location.href = 'home'
      }
    }
  }

  public CopyToClipBoard(LinkToCopy) {
    const inputBox = document.createElement('input');
    inputBox.style.position = 'fixed';
    inputBox.style.left = '0';
    inputBox.style.top = '0';
    inputBox.style.opacity = '0';
    inputBox.value = LinkToCopy;
    document.body.appendChild(inputBox);
    inputBox.focus();
    inputBox.select();
    document.execCommand('copy');
    document.body.removeChild(inputBox);
    this.ShowMessage(1, 'Copied to clipboard.');
  }

  public GetDurationTextForSeconds(p_decTotalSeconds) {

    var l_decTotalSeconds = p_decTotalSeconds as number;
    var l_decTotalSeconds = Number(l_decTotalSeconds);
    var h = Math.floor(l_decTotalSeconds % (3600 * 24) / 3600);
    var m = Math.floor(l_decTotalSeconds % 3600 / 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";

    var duration = hDisplay + mDisplay;//travel time

    return duration;
  }

  public getChanges(previous, current) {
    if (this.isPrimitive(previous) && this.isPrimitive(current)) {
      if (previous === current) {
        return "";
      }

      return current;
    }

    if (this.isObject(previous) && this.isObject(current)) {
      const diff = this.getChanges(Object.entries(previous), Object.entries(current));

      return diff.reduce((merged, [key, value]) => {
        return {
          ...merged,
          [key]: value
        }
      }, {});
    }

    const changes = [];

    if (JSON.stringify(previous) === JSON.stringify(current)) {
      return changes;
    }

    for (let i = 0; i < current.length; i++) {
      const item = current[i];

      if (JSON.stringify(item) !== JSON.stringify(previous[i])) {
        changes.push(item);
      }
    }

    return changes;
  }

  public typeOf(o) {
    return Object.prototype.toString.call(o);
  }

  public isObject(o) {
    return o !== null && !Array.isArray(o) && this.typeOf(o).split(" ")[1].slice(0, -1) === "Object";
  }

  public isPrimitive(o) {
    switch (typeof o) {
      case "object": {
        return false;
      }
      case "function": {
        return false;
      }
      default: {
        return true;
      }
    }
  }

  public isObjectEmpty(object) {
    var isEmpty = true;
    for (var keys in object) {
      isEmpty = false;
      break; // exiting since we found that the object is not empty
    }
    return isEmpty;
  }

  public AddUserTracking(URL, Referrer, PageID = 0, SectionID = 1, TabID = 1, EventID = 1, ParametersList = '', Message = '') {
    let data = {} as any;
    data.URL = URL;
    data.PreviousURL = Referrer;
    data.PTPageListID = PageID;
    data.PTPageSectionID = SectionID;
    data.PTPageTabID = TabID;
    data.PTPageEventID = EventID;
    data.ParametersList = ParametersList;
    data.Message = Message;
    this.http.post('api/PTUserTracking/AddUserTracking', data).subscribe((result) => {

    },
      (error) => console.error(error));
  }

  public AddLogData(LogType, Name, LogData) {
    let data = {} as any;
    data.LogType = LogType;
    data.Name = Name;
    data.LogData = LogData;

    this.http.post('api/SiteUser/AddLogData', data).subscribe((result) => {

    },
      (error) => console.error(error));
  }

  public CheckIsAdminLoggedInUserAndHasPageAccess(URL): Promise<any> {
    let data = {} as any;
    data.URL = URL;
    return this.http.post("api/SiteUser/CheckIsAdminLoggedInUserAndHasPageAccess", data).pipe(map((response: any) => response as any))
      .toPromise()
      .catch(this.handlePromiseError);
  }

  public CheckIsSemiAdminLoggedInUserAndHasPageAccess(URL): Promise<any> {
    let data = {} as any;
    data.URL = URL;
    return this.http.post("api/SiteUser/CheckIsSemiAdminLoggedInUserAndHasPageAccess", data).pipe(map((response: any) => response as any))
      .toPromise()
      .catch(this.handlePromiseError);
  }

  public CheckIsMoverLoggedInUserAndHasPageAccess(URL): Promise<any> {
    let data = {} as any;
    data.URL = URL;
    return this.http.post("api/SiteUser/CheckIsMoverLoggedInUserAndHasPageAccess", data).pipe(map((response: any) => response as any))
      .toPromise()
      .catch(this.handlePromiseError);
  }

  public CheckIsLoggedInUserAndHasPageAccess(URL): Promise<any> {
    let data = {} as any;
    data.URL = URL;
    return this.http.post("api/SiteUser/CheckIsLoggedInUserAndHasPageAccess", data).pipe(map((response: any) => response as any))
      .toPromise()
      .catch(this.handlePromiseError);
  }



  public handlePromiseError(error: Response): Promise<any> {
    throw (error);
  }

}

