import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DefaultTS } from '../SharedComponents/Default';
import { isPlatformBrowser } from '@angular/common';

//import { debug } from 'console';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
})
export class NavMenuComponent {
  //@Output() showLeftMenu : boolean;


  isExpanded = false;
  public http: HttpClient;
  public baseUrl: string;
  public URLComplete = '';
  public ServiceMenu = [] as any;
  public LocationMenu = [] as any;
  public Menus = [] as any;
  public TopMenu = {} as any;
  public TabMenu = [] as any;
  public AcceptingInviteInProgress = false;
  public AcceptingInviteInType = 0;
  public FOSCompanyUserID = 0;
  public UsersInviteData = [] as any;
  public totalcount: number;
  public ShowTopMenu = false;
  public ShowHeader = false;
  public ShowMenutab = false;
  public ShowMenutab1 = false;
  public ShowFinance = false;
  public ShowEmployee = false;
  public ShowGeneral = false;
  public showmenumobile = false;
  public showsubmenumobile1 = false;
  public showsubmenumobile2 = false;
  public showsubmenumobile3 = false;
  public showmenumobileleft = false;
  public PagesWithoutLogin = ["services", "man-with-a-van-london", "references", "insurance", "contact", "signup", "login", "home", "/", "quotes", "confirmation", "", "login", "forgotpassword", "resetpassword", "joinacc", "terms", "privacypolicy", "refundpolicy", "contactus", "joinus", "test", "terms-and-conditions", "privacy", "man-with-a-van-prices", "q", "moverstip", "unsubscribe", "cn"];

  constructor(http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object, private router: Router, public Default: DefaultTS) {
    this.http = http;
    // this.baseUrl = baseUrl;
    this.windowFunction();
    this.GetPageURL();
    this.totalcount = 0;
    this.AjaxLoginCheckLoginData();
    this.LoadTopMenuData();
    this.setTopMenu();
  }
  public HideAlert() {
    console.log('modal closed');
    // document.getElementById("myModal")!.style.display = 'none';
  }
  public windowFunction() {
    if (isPlatformBrowser(this.platformId)) {
      this.URLComplete = window.location.href;
    }
  }

  public AjaxLoginCheckLoginData() {
    let data = {} as any;
    this.http.post('api/SiteUser/AjaxLoginCheckLoginData', data).subscribe((result) => {
      var Result = [] as any;
      Result = result;
      var StatusData = Result[0][0] as any;
      
      this.Default.IsAdminLoggedIn = false;
      if (StatusData.Status == 1) {
        this.Default.UserRole = StatusData.Role;
        this.Default.UserRoleActual = StatusData.RoleActual;
        this.Default.IsSemiAdminLoggedIn = false;
        if (StatusData.Role == "Admin") {
          this.Default.IsAdminLoggedIn = true;
          if (StatusData.RoleActual == "SemiAdmin")
            this.Default.IsSemiAdminLoggedIn = true;
        }
        else if (StatusData.Role == "Driver")
          this.Default.IsDriverLoggedIn = true;
        else if (StatusData.Role == "Helper")
          this.Default.IsHelperLoggedIn = true;

        this.Default.Role.push(StatusData.Role);
        this.Default.ClientInfo.MNVClientID = 0;
        this.Default.IsUserLoggedIn = true;
        this.Default.UserName = StatusData.UserName;
        this.Default.UserEmail = StatusData.Email;
        this.Default.UserMobile = StatusData.Mobile;
        this.Default.ProfileImageURL = StatusData.ImageURL;
        this.Default.UserInitials = StatusData.UserInitials;
        this.Default.UserInitialsSignature = StatusData.UserInitialsSignature;
        this.Default.CompaniesCount = StatusData.CompaniesCount;
        this.Default.IsEditor = StatusData.IsEditor;
        this.Default.ManageCompanies = StatusData.ManageCompanies;
        this.Default.ManageAdmin = StatusData.ManageAdmin;
        this.Default.SelectedCompanyID = StatusData.SelectedCompanyID;
        this.Default.SelectedCompanyName = StatusData.SelectedCompanyName;
        this.Default.SelectedCompanyLogo = StatusData.SelectedCompanyLogo;
        //this.Default.AjaxLoginCheckLoginClientData();

        var ClientData = Result[1] as any;
        if (ClientData && ClientData.length > 0) {
          this.Default.ClientInfo = ClientData[0];
        }

        var MoverData = Result[2] as any;
        if (MoverData[0].IsContractor)
          this.Default.IsDriverAsContractorLoggedIn = true;

        var PageURL = window.location.pathname;
        var l_arrSplittedData = PageURL.split("/");
        if (l_arrSplittedData.length > 0) {
          PageURL = l_arrSplittedData[0];
          if (PageURL == "" && l_arrSplittedData.length > 1)
            PageURL = l_arrSplittedData[1];
        }

        if (StatusData.Role == "Driver" || StatusData.Role == "Helper")
          PageURL = "driverdashboard";
        else if (StatusData.Role == "BlogAdmin") {
          this.Default.IsBlogAdminLoggedIn = true;
          PageURL = "blogarticle";
        }

        if (this.PagesWithoutLogin.indexOf(PageURL) < 0)
          this.ShowTopMenu = false;

      }
      else {
        if (StatusData.LoggedIn == 1) {
          // this.Default.ShowNotification(1, true, StatusData.Message);
        }
        else {
          if (isPlatformBrowser(this.platformId)) {
            if (window.location.href.includes('joinacc') || window.location.href.includes('autoapp')) {
            }
            else {

              var PageURL = window.location.pathname;
              var l_arrSplittedData = PageURL.split("/");
              if (l_arrSplittedData.length > 0) {
                PageURL = l_arrSplittedData[0];
                if (PageURL == "" && l_arrSplittedData.length > 1)
                  PageURL = l_arrSplittedData[1];
              }

              if (this.PagesWithoutLogin.indexOf(PageURL) < 0) {
                if (isPlatformBrowser(this.platformId)) {
                  //this.router.navigate['/login'];
                 // window.location.href = "login";
                }
              }
              else if (this.Default.PageURL.split('?')[0] == 'resetpassword') {
              }
            }
          }
          else {
            this.Default.PageURL = "home";
          }
        }
      }
    },
      (error) => console.error(error));
  }


  public LogoutUser() {
    let data = {} as any;
    this.http
      .post('api/SiteUser/AjaxLoginLogoutUser', data)
      .subscribe(
        (result) => {
          //this.Default.IsUserLoggedIn = true;
          //this.ShowTopMenu = true;
          if (isPlatformBrowser(this.platformId)) {
            window.location.href = "login";
          }
        },
        (error) => console.error(error)
      );
  }
  sidebarToggle() {
    let body = <HTMLElement>document.querySelector('body');
    body.classList.toggle('sidenav-toggled');
  }

  public GetPageURL() {
    if (isPlatformBrowser(this.platformId)) {
      var URL = window.location.href;
      var res = URL.split('/');
      this.Default.PageURL = res[res.length - 1];

    } else {
      this.Default.PageURL = "";
    }
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public setTopMenu() {
    switch (this.Default.PageURL) {
      case 'quote':
      case 'tier':
      case 'mover':
      case 'clients':
      case 'service':
      case 'movertypes':
      case 'vantypes':
      case 'van':
      case 'quotesettings':
      case 'promotionalcode':
      case 'emailtemplates':
      case 'contactorEarnings':
      case 'roles':
      case 'users':
      case 'newjobs':
      case 'messages':
      case 'mybookings':
      case 'myaffiliates':
      case 'role':
      case 'user':
      case 'support':
      case 'pages':
        this.ShowTopMenu = false;
        break;
    }
    if (this.Default.PageURL == 'signup') {
      this.ShowHeader = true;
    }
    else {
      this.ShowHeader = true;
    }
  }

  public LoadTopMenuData() {
    if (this.ShowTopMenu) {
      this.http.get('api/SiteUser/LoadWebSiteMenu').subscribe(
        (result) => {
          var Result = [] as any;
          Result = result;
          this.ServiceMenu = Result[0] as any;
          this.LocationMenu = Result[1] as any;
        },
        (error) => console.error(error)
      );
    }
  }

  public LoadParentData(menuId: number) {
    this.TopMenu.TopLevelID = menuId;
  }

  public NavigateToLink(link: string) {
    if (link.includes('screenviewer')) {
      this.router.navigate([link]);
    } else {
      location.href = link;
    }
  }

  public CheckInviteData() {
    //this.AjaxCheckInviteData();
    //setTimeout(() => {
    //  this.CheckInviteData();
    //}, 60000);
  }

  public AjaxCheckInviteData() {
    let data = {} as any;
    this.http
      .post('api/BaseCompany/LoadUsersInvitesData', data)
      .subscribe(
        (result) => {
          var Result = [] as any;
          Result = result;
          this.UsersInviteData = Result[0] as any;
          if (this.UsersInviteData.length > 0) {
            this.OpenInviteWindow();
          }
        },
        (error) => console.error(error)
      );
  }

  public OpenInviteWindow() {
    this.AcceptingInviteInProgress = false;
    this.AcceptingInviteInType = 0;
    this.FOSCompanyUserID = 0;
    document.getElementById('btnOpenInviteData')!.click();
  }

  public SetJoinCompanyStatus(BaseUserID: number, Status: any) {
    this.AcceptingInviteInProgress = true;
    this.AcceptingInviteInType = Status;
    this.FOSCompanyUserID = BaseUserID;
  }

  public ConfirmStatusInvite(Status: any) {
    if (Status == 1) {
      this.AjaxChangeInviteStatus(
        this.FOSCompanyUserID,
        this.AcceptingInviteInType
      );
    } else {
      this.AcceptingInviteInProgress = false;
      this.AcceptingInviteInType = 0;
      this.FOSCompanyUserID = 0;
    }
  }

  public AjaxChangeInviteStatus(BaseUserID: number, Status: any) {
    document.getElementById('btnOpenInviteData')!.click();
    let data = {} as any;
    data.FOSCompanyUserID = BaseUserID;
    data.Status = Status;
    this.http
      .post('api/BaseCompany/AjaxChangeInviteStatus', data)
      .subscribe(
        (result) => {
          this.AjaxCheckInviteData();
        },
        (error) => console.error(error)
      );
  }
  public CloseMenuAndNavigate() {
    this.showmenumobile = false;
  }
  public CloseLeftMenuAndNavigate() {
    this.showmenumobileleft = false;
  }
}
